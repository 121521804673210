/*+++++++++ fonts +++++++++*/
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "din";
  src: url("../webfonts/din-next-lt-w23-regular.ttf");
}
/*+++++++++ mixins +++++++++*/
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
/*+++++++++ global +++++++++*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 32px;
  scroll-behavior: smooth;
  font-family: "din", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background-color: unset;
}

::-webkit-scrollbar {
  width: 4px !important;
}

::-webkit-scrollbar-track {
  background-color: #fff !important;
}

::-webkit-scrollbar-thumb {
  background: #eee !important;
  height: 300px !important;
}

:target {
  scroll-margin-top: 100px;
}

/*+++++++++ colors +++++++++*/
body {
  direction: rtl;
  position: relative;
  overflow-x: hidden;
}
body header {
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  z-index: 999;
  position: absolute;
  border-bottom: 1px solid #f2f2f2;
  background: #fff;
  transition: position 0.3s ease-in-out;
}
body header.sticky {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: fixed;
  border-color: transparent;
  animation: fadeInDown 0.5s ease-in-out forwards;
}
body header nav {
  display: flex;
  padding: 0;
  height: 100%;
}
body header nav .logo {
  flex: 1;
  gap: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
body header nav .logo img {
  height: 80px;
}
body header nav .nav_links {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
body header nav .nav_links ul {
  height: 100%;
  display: flex;
}
body header nav .nav_links ul li {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}
body header nav .nav_links ul li a {
  font-size: 16px;
  color: #31535a;
  transition: 0.2s ease-in-out;
}
body header nav .nav_links ul li a.active, body header nav .nav_links ul li a:hover {
  color: #81a63d;
}
body header nav .nav_links ul li .drop {
  font-size: 16px;
  color: #31535a;
  cursor: pointer;
  position: relative;
}
body header nav .nav_links ul li.hide-lg {
  display: none;
}
@media (max-width: 992px) {
  body header nav .nav_links {
    background: #fff;
    margin-top: 100px;
    height: calc(100vh - 100px);
    position: absolute;
    right: -100%;
    width: 100%;
    transition: 0.3s ease-in-out;
  }
  body header nav .nav_links ul {
    flex-direction: column;
    justify-content: center;
  }
  body header nav .nav_links ul li {
    height: -moz-fit-content;
    height: fit-content;
    padding: 8px 0;
  }
  body header nav .nav_links ul li.hide-lg {
    display: flex;
  }
  body header nav .nav_links.active {
    right: 0;
  }
}
body header nav .utils {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
body header nav .utils ul {
  height: 100%;
  display: flex;
}
body header nav .utils ul li {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
}
body header nav .utils ul li a {
  color: #31535a;
}
body header nav .utils ul li .profile {
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
}
body header nav .utils ul li .profile img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body header nav .utils ul li .lang_toggler {
  border-radius: 10px;
  color: #81a63d;
  gap: 8px;
  border: 1px solid #81a63d;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px 12px;
}
body header nav .utils ul li .lang_toggler img {
  height: 16px;
}
body header nav .utils ul li .menu-btn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: none;
  z-index: 2;
  flex: 1;
}
@media (max-width: 992px) {
  body header nav .utils ul li .menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-end;
  }
}
body header nav .utils ul li .menu-btn .menu-bar {
  position: relative;
  height: 2px;
  width: 28px;
  background-color: #81a63d;
  transition: 0.5s ease;
}
body header nav .utils ul li .menu-btn .menu-bar::before {
  content: "";
  display: block;
  position: absolute;
  top: -9px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #81a63d;
  transition: 0.5s ease;
}
body header nav .utils ul li .menu-btn .menu-bar::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -9px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #81a63d;
  transition: 0.5s ease;
}
body header nav .utils ul li .menu-btn .menu-bar.active {
  background-color: transparent;
  transform: rotate(90deg);
}
body header nav .utils ul li .menu-btn .menu-bar.active::before {
  transform: translate(0px, 9px) rotate(135deg);
}
body header nav .utils ul li .menu-btn .menu-bar.active::after {
  transform: translate(0px, -9px) rotate(-135deg);
}
@media (max-width: 992px) {
  body header nav .utils ul .hide-sm {
    display: none !important;
  }
}
body header .drop_menu {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body header .drop_menu ul {
  flex-direction: column;
}
body header .drop_menu ul .nav_link {
  padding: 0;
  white-space: nowrap;
  justify-content: flex-start;
}
body header .drop_menu ul .nav_link a {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}
body header .drop_menu ul .nav_link a i {
  color: #81a63d;
}
body main {
  padding-top: 100px;
  min-height: calc(100vh - 100px);
}
body main .auth {
  background-image: url(../images/auth-bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .auth .form_wrapper {
  width: min(100% - 32px, 1042px);
  min-height: 460px;
  background: #fff;
  border-radius: 24px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  display: flex;
  overflow: hidden;
}
@media (max-width: 992px) {
  body main .auth .form_wrapper {
    flex-direction: column;
  }
}
body main .auth .form_wrapper .form_container {
  width: 50%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
body main .auth .form_wrapper .form_container .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .auth .form_wrapper .form_container .logo img {
  height: 100px;
}
body main .auth .form_wrapper .form_container .welcome h4 {
  color: #000000;
  font-size: 28px;
  font-weight: 600;
}
body main .auth .form_wrapper .form_container .welcome p {
  color: #777777;
  margin-bottom: 0;
}
body main .auth .form_wrapper .form_container .forgot a {
  color: #31535a;
  transition: 0.3s ease-in-out;
}
body main .auth .form_wrapper .form_container .forgot a:hover {
  color: #81a63d;
}
body main .auth .form_wrapper .form_container .continue {
  line-height: 26px;
  margin-bottom: 0;
  font-size: 16px;
  color: #31535a;
}
body main .auth .form_wrapper .form_container .continue a {
  line-height: inherit;
  color: #81a63d;
}
body main .auth .form_wrapper .form_container .reset_otp_title {
  padding: 0 16px;
  text-align: center;
  margin-top: 24px;
}
body main .auth .form_wrapper .form_container .reset_otp_title p {
  font-size: 18px;
  color: #31535a;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}
body main .auth .form_wrapper .form_container .reset_otp_title p span {
  color: #81a63d;
}
body main .auth .form_wrapper .form_container .resend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-size: 16px;
}
body main .auth .form_wrapper .form_container .resend p {
  margin-bottom: 0;
}
body main .auth .form_wrapper .form_container .resend .counterDown {
  font-weight: 600;
}
body main .auth .form_wrapper .form_container .tabs {
  width: 100%;
  border-bottom: 2px solid #f2f2f2;
  margin-bottom: 12px;
}
body main .auth .form_wrapper .form_container .tabs button {
  border: none;
  background: none;
  outline: none;
  padding: 0 12px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  color: #000000;
}
@media (max-width: 576px) {
  body main .auth .form_wrapper .form_container .tabs button {
    font-size: 14px;
  }
}
body main .auth .form_wrapper .form_container .tabs button::after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: #31535a;
  transition: 0.3s ease-in-out;
}
body main .auth .form_wrapper .form_container .tabs button.active {
  color: #31535a;
}
body main .auth .form_wrapper .form_container .tabs button.active::after {
  width: 100%;
}
@media (max-width: 992px) {
  body main .auth .form_wrapper .form_container {
    width: 100%;
  }
}
body main .auth .form_wrapper .page_preif {
  width: 50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8392156863), rgba(0, 0, 0, 0.8078431373)), url(../images/auth_thumb.jpeg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 32px;
}
body main .auth .form_wrapper .page_preif .content {
  z-index: 2;
}
body main .auth .form_wrapper .page_preif .content .circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #fff;
}
body main .auth .form_wrapper .page_preif .content h1 {
  margin-top: 12px;
  color: #fff;
  font-size: 48px;
  margin-bottom: 16px;
}
@media (max-width: 576px) {
  body main .auth .form_wrapper .page_preif .content h1 {
    font-size: 36px;
  }
}
body main .auth .form_wrapper .page_preif .content p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 4px;
}
@media (max-width: 576px) {
  body main .auth .form_wrapper .page_preif .content p {
    font-size: 14px;
    line-height: 24px;
  }
}
body main .auth .form_wrapper .page_preif .content h6 {
  font-size: 18px;
  color: #fff;
}
body main .auth .form_wrapper .page_preif .content h6 a {
  color: #81a63d;
}
@media (max-width: 576px) {
  body main .auth .form_wrapper .page_preif .content h6 {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 992px) {
  body main .auth .form_wrapper .page_preif {
    width: 100%;
  }
}
body main .hero_section {
  padding: 40px 0;
}
body main .hero_section .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
body main .hero_section .content h1 {
  font-size: 48px;
  color: #81a63d;
  margin-bottom: 16px;
}
@media (max-width: 576px) {
  body main .hero_section .content h1 {
    font-size: 32px;
  }
}
body main .hero_section .content h6 {
  color: #31535a;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
}
body main .hero_section .content ul {
  color: #777777;
  margin-bottom: 16px;
}
body main .hero_section .content ul li strong {
  font-weight: 400;
}
body main .hero_section .content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
}
body main .hero_section .content ul li::before {
  content: "";
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  background-image: url("../images/starHero.svg");
}
body main .title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
}
body main .title h1 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}
body main .title p {
  color: #31535a;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
body main .title h3 {
  font-size: 20px;
  color: #000000;
  text-align: center;
}
body main .section-header {
  width: 100%;
  height: 140px;
  background: linear-gradient(0deg, rgba(129, 166, 61, 0.77), rgba(129, 166, 61, 0.875)), url("../images/trial.png");
  background-size: cover;
  background-position: 50% 50%;
}
body main .section-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
body main .section-header .container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
body main .section-header .container .content h1 {
  color: #fff;
  margin-bottom: 4px;
}
body main .section-header .container .content p {
  margin: 0;
  color: #fff;
}
body main .section-header .container .content p a {
  color: #fff;
}
body main .section-header .container .thunb {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .section-header .container .thunb img {
  height: 90%;
}
@media (max-width: 576px) {
  body main .section-header .container .thunb {
    display: none;
  }
}
body main .courses {
  padding: 40px 0;
}
body main .courses .inner_search__form {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
}
body main .courses .inner_search__form::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  color: #81a63d;
  content: "\f002";
  font-family: "Font Awesome 6 pro";
}
body main .courses .inner_search__form input {
  width: 100%;
  height: 56px;
  background: #f8f8f8;
  padding: 0 48px 0;
  outline: none;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  font-size: 14px;
}
body main .custom-card {
  direction: rtl;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body main .custom-card .label {
  position: absolute;
  top: 8px;
  left: 8px;
  background: #81a63d;
  color: #fff;
  font-size: 14px;
  padding: 0px 16px;
  border-radius: 4px;
}
body main .custom-card h6 {
  font-size: 14px;
  color: #31535a;
  font-weight: 600;
  margin-top: 4px;
}
body main .custom-card .img {
  width: 100%;
  aspect-ratio: 3/2;
}
body main .custom-card .img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body main .custom-card .content {
  padding: 16px;
}
body main .custom-card .content h4 {
  font-size: 24px;
  color: #81a63d;
}
body main .custom-card .content h4.smallhead {
  font-size: 20px;
}
body main .custom-card .content a {
  width: 28px;
  font-size: 14px;
  height: 28px;
  background: #81a63d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
body main .custom-card .content p {
  margin: 0;
  font-weight: 500;
  color: #31535a;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
}
body main .custom-card .content p img {
  height: 16px;
}
body main .custom-card .content .likes_container {
  display: flex;
  gap: 16px;
}
body main .custom-card .content .likes_container span {
  color: #000000;
  line-height: normal;
}
body main .custom-card .content .likes_container .dislikes {
  display: flex;
  align-items: center;
  gap: 6px;
}
body main .custom-card .content .likes_container .dislikes button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cecece;
  font-size: 16px;
}
body main .custom-card .content .likes_container .dislikes button.active {
  color: red !important;
}
body main .custom-card .content .likes_container .likes {
  display: flex;
  align-items: center;
  gap: 6px;
}
body main .custom-card .content .likes_container .likes button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cecece;
  font-size: 16px;
}
body main .custom-card .content .likes_container .likes button.active {
  color: #81a63d !important;
}
body main .custom-card .content .likes_container.disabled {
  pointer-events: none;
}
body main .custom-card.longImg .img img {
  aspect-ratio: 3/2;
}
body main .custom-card.longImg .content h4 {
  margin: 0;
  font-size: 18px;
}
@media (max-width: 576px) {
  body main .custom-card.longImg .content h4 {
    font-size: 16px;
  }
}
body main .custom-card .blur_overLay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.73);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}
body main .custom-card .blur_overLay img {
  height: 52px;
}
body main .custom-card .blur_overLay p {
  color: #fff;
  margin: 0;
  font-weight: 600;
}
body main .custom-card .sno {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body main .custom-card .sno b {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 28px;
  font-size: 14px;
}
body main .custom-card .sno b i {
  font-size: 14px;
  color: #81a63d;
}
body main .custom-card .sno span {
  background: #81a63d;
  color: #fff;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 2px;
}
body main .audio-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body main .audio-card .img {
  position: relative;
  width: 100%;
}
body main .audio-card .img img {
  aspect-ratio: 3/2;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body main .audio-card .img .subscribe {
  position: absolute;
  top: 12px;
  right: 12px;
  width: auto;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  background: #81a63d;
  color: #fff;
  padding: 0 10px;
}
body main .audio-card .img .remove {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  top: 12px;
  left: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: red;
  color: #fff;
}
body main .audio-card .content {
  padding: 16px;
}
body main .audio-card .content h4 {
  font-size: 16px;
  font-weight: 600;
  color: #31535a;
  margin: 0;
}
body main .audio-card .content .play_btn {
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  cursor: pointer;
  background: #81a63d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
body main .audio-card .content .play_btn i {
  font-size: 12px;
}
body main .audio-card .content .play_btn.lock {
  background: red;
}
body main .audio-card .content p {
  margin: 0;
  font-weight: 500;
  color: #31535a;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  gap: 4px;
  align-items: center;
}
body main .audio-card .content p img {
  height: 16px;
}
body main .audio-card .content p span {
  font-size: 14px;
  font-weight: 600;
}
body main .audio-card .content .likes_container {
  display: flex;
  gap: 16px;
}
body main .audio-card .content .likes_container span {
  color: #000000;
  line-height: normal;
}
body main .audio-card .content .likes_container .dislikes {
  display: flex;
  align-items: center;
  gap: 6px;
}
body main .audio-card .content .likes_container .dislikes button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cecece;
  font-size: 16px;
}
body main .audio-card .content .likes_container .dislikes button.active {
  color: red !important;
}
body main .audio-card .content .likes_container .likes {
  display: flex;
  align-items: center;
  gap: 6px;
}
body main .audio-card .content .likes_container .likes button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cecece;
  font-size: 16px;
}
body main .audio-card .content .likes_container .likes button.active {
  color: #81a63d !important;
}
body main .audio-card .content .likes_container.disabled {
  pointer-events: none;
}
body main .job-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body main .job-card .img {
  width: 100%;
}
body main .job-card .img img {
  aspect-ratio: 3/2;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body main .job-card .content {
  padding: 16px;
}
body main .job-card .content h4 {
  font-size: 18px;
  margin-bottom: 0;
  color: #81a63d;
}
body main .job-card .content p {
  margin-bottom: 0;
  font-weight: 500;
  color: #31535a;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
body main .job-card .content a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #81a63d;
  padding: 4px 0;
  border-radius: 8px;
  color: #fff;
}
body main .video_wrapper {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/2;
  border-radius: 16px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  isolation: isolate;
}
body main .video_wrapper .play-btn {
  cursor: pointer;
  width: 80px;
  height: 80px;
  background: #81a63d;
  border-radius: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
body main .video_wrapper .play-btn i {
  font-size: 28px;
  color: #fff;
}
body main .video_wrapper .waves {
  position: absolute;
  width: 180px;
  height: 180px;
  background: rgba(255, 255, 255, 0.4274509804);
  opacity: 0;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  animation: waves 3s ease-in-out infinite;
}
body main .video_wrapper .wave-1 {
  animation-delay: 0s;
}
body main .video_wrapper .wave-2 {
  animation-delay: 1s;
}
body main .video_wrapper .wave-3 {
  animation-delay: 2s;
}
@keyframes waves {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
body main .course-details {
  padding: 40px 0 0;
}
body main .course-details .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #31535a;
  color: #fff;
  padding: 10px 0;
  margin-top: 12px;
  transition: 0.3s ease-in-out;
}
body main .course-details .btn:hover {
  background: #81a63d;
}
body main .course-details .alreadySub {
  margin-top: 12px;
}
body main .course-details .alreadySub a {
  color: #81a63d;
}
body main .course-details .video_wrapper {
  height: unset;
  position: relative;
}
body main .course-details .video_wrapper span {
  position: relative;
  top: 8px;
  right: 8px;
  background: #81a63d;
  color: #fff;
  padding: 4px 8px;
  position: absolute;
  border-radius: 8px;
}
body main .course-details .content h4 {
  font-size: 24px;
  color: #31535a;
  font-weight: 600;
}
body main .course-details .content P {
  font-size: 14px;
  color: #777777;
  line-height: 26px;
}
body main .course-details .content h6 {
  font-size: 18px;
  color: #31535a;
  font-weight: 600;
}
body main .course-details .content ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;
}
@media (max-width: 576px) {
  body main .course-details .content ul {
    grid-template-columns: repeat(1, 1fr);
  }
}
body main .course-details .content ul li {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  font-size: 13px;
  color: #777777;
  line-height: 24px;
}
body main .course-details .content ul li::before {
  content: "\f648";
  font-family: "Font Awesome 6 pro";
  color: #81a63d;
}
body main .course-details .content ul.iconCheck li::before {
  content: "\f00c";
}
body main .course_faqs {
  padding: 40px 0;
  background: #f8f8f8;
}
body main .course_faqs .title {
  text-align: center;
  color: #31535a;
  font-size: 32px;
  margin-bottom: 32px;
  position: relative;
}
body main .course_faqs .title::after {
  position: absolute;
  content: "";
  top: 45px;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: #81a63d;
}
body main .in_course_why_us {
  padding: 40px 0;
  background: #f8f8f8;
}
body main .in_course_why_us .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
body main .in_course_why_us .title .arrow {
  height: 100px;
}
body main .in_course_why_us .title h3 {
  text-align: center;
  color: #31535a;
  font-size: 32px;
  margin-bottom: 32px;
}
body main .in_course_why_us .grantee_card {
  background: #fff;
  box-shadow: rgba(189, 189, 189, 0.2) 0px 2px 8px 0px;
  height: 100%;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body main .in_course_why_us .grantee_card .icon img {
  margin-bottom: 12px;
  height: 80px;
}
body main .in_course_why_us .grantee_card h4 {
  font-size: 22px;
  color: #81a63d;
}
body main .in_course_why_us .grantee_card p {
  color: #777777;
  font-size: 14px;
  margin: 0;
  line-height: 24px;
  text-align: center;
}
body main .course_reviews {
  padding: 40px 0;
}
body main .course_reviews .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
}
body main .course_reviews .title h3 {
  text-align: center;
  color: #31535a;
  font-size: 32px;
  margin-bottom: 0;
}
body main .course_reviews .title p {
  font-size: 14px;
  color: #777777;
  text-align: center;
  margin: 0;
}
body main .course_reviews .swiper-slide {
  height: auto;
}
body main .course_reviews .testimonial_card {
  border: 1px solid rgba(129, 166, 61, 0.1411764706);
  padding: 16px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 12px;
  gap: 12px;
}
body main .course_reviews .testimonial_card p {
  margin: 0;
  text-align: center;
  line-height: 24px;
}
body main .course_reviews .testimonial_card .play_btn {
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  font-size: 14px;
  cursor: pointer;
  background: #81a63d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
body main .course_reviews .testimonial_card .sound {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
}
body main .course_reviews .testimonial_card .sound .waves_img {
  width: 100%;
  max-width: 100px;
  height: 48px;
  -webkit-mask-image: url("../images/waves.svg");
          mask-image: url("../images/waves.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  position: relative;
}
body main .course_reviews .testimonial_card .sound .waves_img .wave {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  background-image: url("../images/waves.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
body main .course_reviews .testimonial_card .sound .waves_img .overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  background-color: #81a63d;
}
body main .course_reviews .testimonial_card .img {
  width: 100%;
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .course_reviews .testimonial_card .img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body main .course_reviews .testimonial_card .img video {
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 240px;
}
body main .course_reviews .testimonial_card .description {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .course_reviews .testimonial_card .description p {
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  color: #31535a;
}
body main .course_reviews .testimonial_card .rate {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
body main .section_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body main .section_title h3 {
  color: #81a63d;
  font-size: 24px;
}
body main .section_title a {
  color: #31535a;
  transition: 0.3s ease-in-out;
}
body main .section_title a:hover {
  color: #81a63d;
}
body main .section_title a i {
  margin-right: 4px;
  transform: translateY(2px);
}
body main .library_section {
  padding: 40px 0;
}
@media screen and (max-width: 576px) {
  body main .library_section {
    padding: 20px 0;
  }
}
body main .library_section .create_list button {
  border-radius: 8px;
  padding: 4px 12px;
  background: #81a63d;
  color: #fff;
}
body main .library_section .list-card {
  padding: 12px;
  display: flex;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  gap: 8px;
}
body main .library_section .list-card .icon {
  width: 80px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-radius: 8px;
}
body main .library_section .list-card .icon img {
  height: 36px;
}
body main .library_section .list-card .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 4px 0;
}
body main .library_section .list-card .content h6 {
  color: #000000;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}
body main .library_section .list-card .content p {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  line-height: normal;
  font-size: 14px;
  color: #777777;
}
body main .library_section .list-card .content p img {
  height: 16px;
}
body main .library_section .list-card .buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-right: auto;
  color: #81a63d;
}
body main .library_section .list-card .buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  color: #81a63d;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 14px;
}
body main .library_section .list-card .buttons a:nth-child(2) {
  color: red;
}
body main .library_section .swiper_pagination_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body main .library_section .swiper_pagination_title h5 {
  color: #31535a;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 12px;
}
body main .library_section .swiper_pagination_title h5 img {
  height: 22px;
}
body main .library_section .swiper_pagination_title .swiper_pagination {
  display: flex;
  align-items: center;
  gap: 12px;
}
body main .library_section .swiper_pagination_title .swiper_pagination button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #31535a;
  color: #31535a;
}
body main .library_section .swiper_pagination_title .swiper_pagination button:hover {
  background: #81a63d;
  border-color: #81a63d;
  color: #fff;
}
body main .library_section .swiper-slide {
  height: auto;
}
body main .library_section .listCard {
  padding: 12px 16px;
  border-radius: 12px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
}
body main .library_section .listCard h5 {
  font-size: 16px;
  font-weight: 600;
}
body main .library_section .listCard .img img {
  height: 50px;
}
body main .library_section .noDataFound {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
}
body main .library_section .noDataFound img {
  height: 280px;
}
body main .library_section .noDataFound h5 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}
body main .library_section .noDataFound p {
  color: #777777;
  margin-bottom: 4px;
}
body main .library_section .noDataFound button,
body main .library_section .noDataFound a {
  background: #81a63d;
  color: #fff;
  padding: 4px 24px;
  border-radius: 20px;
}
body main .library_section .sound_img {
  width: 100%;
  aspect-ratio: 3/2;
  border-radius: 12px;
  overflow: hidden;
}
body main .library_section .sound_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body main .library_section .likes_container {
  display: flex;
  gap: 24px;
}
body main .library_section .likes_container span {
  color: #000000;
  line-height: normal;
}
body main .library_section .likes_container .dislikes {
  display: flex;
  align-items: center;
  gap: 6px;
}
body main .library_section .likes_container .dislikes button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cecece;
  font-size: 18px;
}
body main .library_section .likes_container .dislikes button.active {
  color: red !important;
}
body main .library_section .likes_container .likes {
  display: flex;
  align-items: center;
  gap: 6px;
}
body main .library_section .likes_container .likes button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cecece;
  font-size: 18px;
}
body main .library_section .likes_container .likes button.active {
  color: #81a63d !important;
}
body main .library_section .likes_container.disabled {
  pointer-events: none;
}
body main .library_section .sound_content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
body main .library_section .sound_content h5 {
  font-size: 26px;
  color: #81a63d;
}
@media screen and (max-width: 567px) {
  body main .library_section .sound_content h5 {
    font-size: 22px;
  }
}
body main .library_section .sound_content p {
  line-height: 28px;
  font-size: 16px;
  color: #777777;
}
body main .library_section .sound_content .subscribe {
  color: #fff;
  background: #81a63d;
  padding: 4px 24px;
  border-radius: 20px;
}
body main .library_section .sound_content .buttons {
  display: flex;
  gap: 28px;
}
body main .library_section .sound_content .buttons .add_to_library {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
}
@media screen and (max-width: 567px) {
  body main .library_section .sound_content .buttons .add_to_library {
    font-size: 12px;
  }
}
body main .library_section .sound_content .buttons .add_to_library span {
  font-size: 13px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  background: #81a63d;
}
body main .library_section .sound_content .buttons .add_to_library.danger span {
  background: red;
}
body main .library_section .sound_content .buttons .play_btnn {
  background: #31535a;
  padding: 2px 12px;
  border-radius: 20px;
  display: flex;
  color: #fff;
  width: 120px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 8px;
}
body main .library_section .sound_content .buttons .play_btnn i {
  font-size: 14px;
}
body main .library_section .sound_content .play_btnn {
  background: #31535a;
  padding: 2px 12px;
  border-radius: 20px;
  display: flex;
  color: #fff;
  width: 120px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 8px;
}
body main .library_section .sound_content .play_btnn i {
  font-size: 14px;
}
body main .swiper {
  padding: 4px 4px 60px;
}
body main .swiper .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #81a63d;
}
body main .swiper .swiper-slide {
  height: auto;
}
body main .feature_and_statistics {
  padding: 60px 0;
}
body main .feature_and_statistics .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}
body main .feature_and_statistics .features {
  display: flex;
  gap: 60px;
  flex-direction: column;
}
body main .feature_and_statistics .features.toTop {
  transform: translateY(-20px);
}
body main .feature_and_statistics .features.toBottom {
  transform: translateY(20px);
}
@media (max-width: 768px) {
  body main .feature_and_statistics .features {
    gap: 24px;
  }
}
body main .feature_and_statistics .features .feature {
  cursor: pointer;
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.3s ease-in-out;
  border-radius: 12px;
}
body main .feature_and_statistics .features .feature p {
  font-size: 14px;
  line-height: 24px;
  color: #31535a;
  margin: 0;
}
body main .feature_and_statistics .features .feature h4 {
  font-size: 18px;
  font-weight: 600;
  color: #31535a;
  margin: 0;
}
body main .feature_and_statistics .features .feature.active {
  background: #81a63d;
}
body main .feature_and_statistics .features .feature.active h4,
body main .feature_and_statistics .features .feature.active p {
  color: #fff;
  display: block;
}
body main .feature_and_statistics .statistic {
  padding: 24px;
  background: #f8f8f8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body main .feature_and_statistics .statistic p {
  font-size: 18px;
  margin: 0;
  color: #31535a;
}
@media (max-width: 576px) {
  body main .feature_and_statistics .statistic p {
    font-size: 14px;
  }
}
body main .feature_and_statistics .statistic h3 {
  font-size: 36px;
  font-weight: 600;
  margin: 0;
  color: #81a63d;
}
body main .feature_and_statistics .good_Experience {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
}
body main .feature_and_statistics .good_Experience .notSatisfied {
  padding: 12px 24px;
  border-radius: 49% 51% 51% 49%/48% 51% 49% 52%;
  border: 2px solid #81a63d;
}
body main .feature_and_statistics .good_Experience .notSatisfied h5 {
  margin: 0;
  color: #81a63d;
  font-size: 16px;
}
body main .feature_and_statistics .good_Experience .solutions {
  display: flex;
  gap: 32px;
  margin-top: 24px;
  width: 100%;
}
@media (max-width: 576px) {
  body main .feature_and_statistics .good_Experience .solutions {
    flex-direction: column;
    gap: 16px;
  }
}
body main .feature_and_statistics .good_Experience .solutions .solution {
  padding: 16px 12px;
  border-radius: 12px;
  width: 100%;
  display: flex;
  gap: 12px;
  background: #31535a;
  flex: 1;
}
body main .feature_and_statistics .good_Experience .solutions .solution .icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .feature_and_statistics .good_Experience .solutions .solution .icon img {
  width: 50px;
}
body main .feature_and_statistics .good_Experience .solutions .solution .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
body main .feature_and_statistics .good_Experience .solutions .solution .text p {
  margin: 0;
  color: #fff;
}
body main .feature_and_statistics .good_Experience .solutions .solution .text h6 {
  font-size: 18px;
  margin-bottom: 4px;
  color: #fff;
}
body main .feature_and_statistics .good_Experience .solutions .solution:nth-child(2) {
  background: #81a63d;
}
body main .library {
  padding: 60px 0 0;
}
body main .library .title {
  width: 100%;
  text-align: center;
  color: #81a63d;
  margin-bottom: 0;
}
body main .library .section_subtitle {
  width: 100%;
  text-align: center;
  color: #31535a;
  margin-bottom: 32px;
  line-height: 24px;
}
body main .libirary_subscribe {
  padding: 40px 0;
}
body main .libirary_subscribe .title_section h1 {
  text-align: center;
  color: #31535a;
  font-size: 32px;
}
@media screen and (max-width: 576px) {
  body main .libirary_subscribe .title_section h1 {
    font-size: 26px;
  }
}
body main .libirary_subscribe .title_section p {
  text-align: center;
  font-size: 18px;
  color: #777777;
}
@media screen and (max-width: 576px) {
  body main .libirary_subscribe .title_section p {
    font-size: 14px;
  }
}
body main .libirary_subscribe .plan {
  height: 100%;
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body main .libirary_subscribe .plan .convertion {
  color: red;
  line-height: normal;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
body main .libirary_subscribe .plan h3 {
  font-size: 28px;
  color: #31535a;
}
body main .libirary_subscribe .plan ul {
  display: flex;
  gap: 8px;
  position: relative;
  flex-direction: column;
  margin-bottom: 12px;
}
body main .libirary_subscribe .plan ul li {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  font-size: 14px;
  color: #777777;
  line-height: 24px;
}
body main .libirary_subscribe .plan ul li::before {
  content: "\f058";
  font-weight: 600;
  font-size: 16px;
  color: #81a63d;
  font-family: "Font Awesome 6 pro";
}
body main .libirary_subscribe .plan .line {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background: #f2f2f2;
}
body main .libirary_subscribe .plan .price_subscribe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}
body main .libirary_subscribe .plan .price_subscribe button {
  background: #81a63d;
  color: #fff;
  font-size: 16px;
  width: auto;
  padding: 2px 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .libirary_subscribe .plan .price_subscribe .price {
  display: flex;
  align-items: center;
  gap: 8px;
}
body main .libirary_subscribe .plan .price_subscribe .price h5 {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #81a63d;
  margin: 0;
}
body main .libirary_subscribe .plan .price_subscribe .price h5 span {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  line-height: normal;
}
body main .libirary_subscribe .plan .price_subscribe .price p {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  line-height: normal;
  color: #777777;
  text-decoration: line-through;
}
body main .libirary_subscribe .plan .price_subscribe .price p span {
  line-height: normal;
}
body main .our_parteners {
  padding: 60px 0;
}
body main .our_parteners .slider_wrapper {
  background: #81a63d;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body main .our_parteners .slider_wrapper h3 {
  color: #fff;
  font-size: 32px;
  margin-bottom: 8px;
}
body main .our_parteners .slider_wrapper p {
  color: #fff;
}
body main .our_parteners .slider_wrapper .slider {
  width: 100%;
}
body main .our_parteners .slider_wrapper .slider .swiper {
  padding: 0;
}
body main .our_parteners .slider_wrapper .slider .img {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .our_parteners .slider_wrapper .slider .img img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(0) invert(1);
  transition: 0.3s ease-in-out;
}
body main .our_parteners .slider_wrapper .slider .img::after {
  content: "";
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
  background-color: #fff;
  transition: 0.3s ease-in-out;
  z-index: -1;
}
body main .our_parteners .slider_wrapper .slider .img:hover img {
  filter: none;
}
body main .our_parteners .slider_wrapper .slider .img:hover::after {
  content: "";
  height: 100%;
}
body main .jobs_page {
  padding: 60px 0;
}
body main .what_we_offer {
  padding-bottom: 60px;
}
body main .what_we_offer .table_container {
  overflow: scroll;
  padding-bottom: 8px;
  width: 100%;
}
body main .what_we_offer .table_container::-webkit-scrollbar {
  height: 3px;
}
body main .what_we_offer table {
  white-space: nowrap;
  width: 100%;
  border: 1px solid #f2f2f2;
  border-collapse: collapse;
  border-radius: 16px;
  position: relative;
}
body main .what_we_offer table thead tr th img {
  height: 80px;
}
body main .what_we_offer table td,
body main .what_we_offer table th {
  padding: 8px 16px;
  border: 1px solid #f2f2f2;
  position: relative;
  min-width: 250px;
  max-width: 250px;
  white-space: normal;
}
body main .what_we_offer table td .icon,
body main .what_we_offer table th .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .what_we_offer table td .check,
body main .what_we_offer table th .check {
  color: #81a63d;
}
body main .what_we_offer table td .x,
body main .what_we_offer table th .x {
  color: #f02020;
}
body main .what_we_offer table td.circled,
body main .what_we_offer table th.circled {
  padding: 8px 24px;
}
body main .what_we_offer table td.circled:before,
body main .what_we_offer table th.circled:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  right: 8px;
  height: 8px;
  background: #81a63d;
  border-radius: 50%;
}
body main .what_we_offer table thead th:nth-child(2) {
  border-left: 2px solid #81a63d;
  border-top: 2px solid #81a63d;
}
body main .what_we_offer table thead th:nth-child(1) {
  border-left: 2px solid #81a63d;
}
body main .what_we_offer table tbody tr td:nth-child(2),
body main .what_we_offer table tbody tr td:nth-child(1) {
  border-left: 2px solid #81a63d;
}
body main .what_we_offer table tbody tr:last-child td:nth-child(2) {
  border-bottom: 2px solid #81a63d;
}
body main .testimonials {
  padding: 60px 0 30px;
  background: rgba(129, 166, 61, 0.1411764706);
}
body main .testimonials .testimonial_card {
  height: 100%;
  border: 1px solid rgba(129, 166, 61, 0.1411764706);
  padding: 16px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
}
body main .testimonials .testimonial_card p {
  margin: 0;
  text-align: center;
  line-height: 24px;
}
body main .testimonials .testimonial_card .play_btn {
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  font-size: 14px;
  cursor: pointer;
  background: #81a63d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
body main .testimonials .testimonial_card .sound {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
}
body main .testimonials .testimonial_card .sound .waves_img {
  width: 100%;
  max-width: 100px;
  height: 48px;
  -webkit-mask-image: url("../images/waves.svg");
          mask-image: url("../images/waves.svg");
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  position: relative;
}
body main .testimonials .testimonial_card .sound .waves_img .wave {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  background-image: url("../images/waves.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
body main .testimonials .testimonial_card .sound .waves_img .overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  background-color: #81a63d;
}
body main .job_details {
  padding: 40px 0;
}
body main .job_details .content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
body main .job_details .content .job_title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
body main .job_details .content .job_title h4 {
  font-size: 28px;
  margin-bottom: 0;
  color: #31535a;
}
body main .job_details .content .job_title h4 i {
  color: #81a63d;
  font-size: 20px;
}
@media screen and (max-width: 576px) {
  body main .job_details .content .job_title h4 {
    font-size: 18px;
  }
}
body main .job_details .content .job_title a {
  background: #81a63d;
  color: #fff;
  padding: 4px 16px;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}
body main .job_details .content .job_title a:hover {
  background: #31535a;
}
body main .job_details .content .job_title li {
  color: #31535a;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}
body main .job_details .content .job_title li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #81a63d;
}
body main .job_details .content .about_job p {
  color: #777777;
}
body main .job_details .content .about_job h6 {
  color: #31535a;
  font-size: 16px;
  font-weight: 600;
}
body main .job_details .content .about_job ul {
  list-style: disc;
  color: #777777;
  padding-right: 16px;
  margin-bottom: 16px;
}
body main .job_details .content .about_job ul li {
  line-height: 28px;
}
body main .apply-for-job,
body main .contact_section {
  padding: 40px 0;
}
body main .apply-for-job h3,
body main .contact_section h3 {
  text-align: center;
  color: #81a63d;
  font-size: 28px;
  margin-bottom: 24px;
}
body main .apply-for-job .form-ui,
body main .contact_section .form-ui {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 16px;
  padding: 32px 24px;
}
@media (max-width: 576px) {
  body main .apply-for-job .form-ui .form_group,
  body main .contact_section .form-ui .form_group {
    flex-direction: column;
  }
}
body main .apply-for-job .form-ui .buttons,
body main .contact_section .form-ui .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
body main .apply-for-job .form-ui .buttons button,
body main .contact_section .form-ui .buttons button {
  width: auto;
  height: 48px;
  padding: 0 24px;
  margin: 0;
  border-radius: 8px;
}
body main .apply-for-job .form-ui .buttons button.next,
body main .contact_section .form-ui .buttons button.next {
  background: #81a63d;
  color: #fff;
}
body main .apply-for-job .form-ui .buttons button.back,
body main .contact_section .form-ui .buttons button.back {
  background: transparent;
  border: 1px solid #31535a;
  color: #31535a;
}
body main .apply-for-job .form-ui textarea,
body main .contact_section .form-ui textarea {
  height: 100px;
}
body main .apply-for-job .form-ui .cv_area,
body main .contact_section .form-ui .cv_area {
  width: 100%;
  padding: 16px;
  background: #f8f8f8;
  gap: 6px;
  border-radius: 12px;
  flex-direction: column;
  border: 1px solid #f2f2f2;
  cursor: pointer;
  color: #777777;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .apply-for-job .form-ui .cv_area input,
body main .contact_section .form-ui .cv_area input {
  display: none;
}
body main .apply-for-job .form-ui .cv_area span,
body main .contact_section .form-ui .cv_area span {
  color: #000000;
  line-height: normal;
}
body main .apply-for-job .form-ui .cv_area .content,
body main .contact_section .form-ui .cv_area .content {
  display: flex;
  align-items: center;
  gap: 4px;
}
body main .apply-for-job .form-ui .cv_area .content p,
body main .contact_section .form-ui .cv_area .content p {
  margin: 0;
  line-height: normal;
}
body main .apply-for-job .contact_info h3,
body main .contact_section .contact_info h3 {
  color: #31535a;
  font-size: 20px;
}
body main .profile_sec {
  padding: 40px 0;
}
@media screen and (max-width: 576px) {
  body main .profile_sec .form-ui .form_group {
    flex-direction: column !important;
  }
}
body main .terms_section {
  padding: 40px 0;
}
body main .terms_section h4 {
  font-size: 20px;
  color: #81a63d;
}
body main .terms_section p {
  color: #777777;
}
body main .terms_section p a {
  color: #81a63d;
}
body main .terms_section ul {
  margin-bottom: 16px;
  list-style: decimal;
  padding-right: 16px;
}
body main .terms_section ul li {
  color: #31535a;
}
body main .my_courses {
  padding: 40px 0;
}
body main .my_courses .my_courses_title {
  display: flex;
  align-items: center;
  gap: 16px;
}
body main .my_courses .my_courses_title img {
  height: 36px;
}
body main .my_courses .my_courses_title h2 {
  color: #31535a;
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}
body main .my_courses .noCourses {
  height: 400px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
body main .my_courses .noCourses h3 {
  color: #e4e4e4;
  font-weight: 700;
  font-size: 60px;
}
@media screen and (max-width: 576px) {
  body main .my_courses .noCourses h3 {
    font-size: 40px;
  }
}
body main .my_courses .noCourses a {
  background: #81a63d;
  color: #fff;
  padding: 8px 24px;
  border-radius: 100px;
}
body main .course_dashboard {
  padding: 16px 0;
}
body main .course_dashboard .course_dashboard_router {
  padding-top: 0;
}
body main .course_dashboard .course_dashboard_router .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  body main .course_dashboard .course_dashboard_router .header {
    gap: 8px;
    flex-direction: column-reverse;
  }
}
body main .course_dashboard .course_dashboard_router .header .title {
  height: 40px;
  margin: 0;
  flex-direction: row;
  gap: 24px;
}
body main .course_dashboard .course_dashboard_router .header .title h5 {
  color: #31535a;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}
body main .course_dashboard .course_dashboard_router .header .title .date {
  font-size: 14px;
  color: #81a63d;
}
@media screen and (max-width: 576px) {
  body main .course_dashboard .course_dashboard_router .header .title {
    align-self: flex-start;
  }
}
@media screen and (max-width: 768px) {
  body main .course_dashboard .course_dashboard_router .header .buttons {
    width: 100%;
  }
  body main .course_dashboard .course_dashboard_router .header .buttons .dropdown {
    width: 100%;
  }
}
body main .course_dashboard .course_dashboard_router .header button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #81a63d;
  color: #fff;
  white-space: nowrap;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  border: 0;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 576px) {
  body main .course_dashboard .course_dashboard_router .header button {
    padding: 0 16px;
    width: 100%;
  }
}
body main .course_dashboard .course_dashboard_router .header button i {
  font-size: 14px;
}
body main .course_dashboard .course_dashboard_router .header button:hover {
  background: #31535a;
}
body main .course_dashboard .course_dashboard_router .noStudents {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body main .course_dashboard .course_dashboard_router .noStudents h5 {
  text-align: center;
  color: #e4e4e4;
  font-weight: 700;
  font-size: 40px;
}
@media screen and (max-width: 576px) {
  body main .course_dashboard .course_dashboard_router .noStudents h5 {
    font-size: 28px;
  }
}
body main .course_dashboard .course_dashboard_router .noStudents p {
  text-align: center;
  line-height: 26px;
}
body main .course_dashboard .course_dashboard_router .noAppointments {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body main .course_dashboard .course_dashboard_router .noAppointments img {
  height: 250px;
}
body main .course_dashboard .course_dashboard_router .noAppointments p {
  font-size: 20px;
  color: #31535a;
}
body main .course_dashboard .course_dashboard_router .noAppointments button {
  background: #81a63d;
  color: #fff;
  padding: 4px 32px;
  border-radius: 100px;
}
body main .course_dashboard .course_dashboard_router .forWhom {
  color: #31535a;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
}
body main .course_dashboard .course_dashboard_router .forWhom img {
  height: 20px;
}
body main .course_dashboard .course_dashboard_router .time_table {
  padding-bottom: 4px;
  margin-top: 10px;
  overflow-x: scroll;
}
body main .course_dashboard .course_dashboard_router .time_table::-webkit-scrollbar {
  height: 2px;
}
body main .course_dashboard .course_dashboard_router .time_table table {
  white-space: nowrap;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #f2f2f2;
}
body main .course_dashboard .course_dashboard_router .time_table th {
  font-size: 14px;
  background: #f8f8f8;
  color: #31535a;
}
body main .course_dashboard .course_dashboard_router .time_table th,
body main .course_dashboard .course_dashboard_router .time_table td {
  padding: 8px;
  border: 1px solid #f2f2f2;
}
body main .course_dashboard .course_dashboard_router .time_table th button,
body main .course_dashboard .course_dashboard_router .time_table td button {
  padding: 0 12px;
}
body main .course_dashboard .course_dashboard_router .time_table th button img,
body main .course_dashboard .course_dashboard_router .time_table td button img {
  height: 22px;
}
body main .course_dashboard .course_dashboard_router .course_reports iframe {
  height: 600px;
  width: 100%;
}
body main .course_dashboard .course_dashboard_router .dashboard_heading {
  color: #31535a;
  font-size: 18px;
  font-weight: 600;
}
body main .course_dashboard .course_dashboard_router .dashboard_sub_heading {
  font-size: 16px;
  margin: 8px 0 0;
  color: #81a63d;
}
body main .course_dashboard .course_dashboard_router .images_grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
body main .course_dashboard .course_dashboard_router .images_grid .file_upload {
  border: 1px dashed #81a63d;
  border-radius: 12px;
  width: 170px;
  height: 150px;
}
body main .course_dashboard .course_dashboard_router .images_grid .file_upload label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .course_dashboard .course_dashboard_router .images_grid .file_upload label input {
  display: none;
}
body main .course_dashboard .course_dashboard_router .images_grid .file_upload label img {
  height: 60px;
}
body main .course_dashboard .course_dashboard_router .images_grid .uploaded_file {
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  width: 170px;
  position: relative;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .course_dashboard .course_dashboard_router .images_grid .uploaded_file img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body main .course_dashboard .course_dashboard_router .images_grid .uploaded_file button {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 26px;
  font-size: 16px;
  height: 26px;
  border-radius: 50%;
  background: #81a63d;
  color: #fff;
}
body main .course_dashboard .course_dashboard_router .images_grid .uploaded_file .doc_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
body main .course_dashboard .course_dashboard_router .images_grid .uploaded_file .doc_icon span {
  font-size: 10px;
  line-height: normal;
  color: #81a63d;
}
body main .course_dashboard .course_dashboard_router .images_grid .uploaded_file .doc_icon img {
  width: auto;
  height: 50px;
}
body main .course_dashboard .course_dashboard_router .submit_btn {
  padding: 0 32px;
  width: -moz-fit-content;
  width: fit-content;
}
body main .course_dashboard .course_dashboard_router .dashboard_course_card {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 12px;
  overflow: hidden;
  gap: 16px;
}
body main .course_dashboard .course_dashboard_router .dashboard_course_card .img {
  flex: 1;
  width: 100%;
}
body main .course_dashboard .course_dashboard_router .dashboard_course_card .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body main .course_dashboard .course_dashboard_router .dashboard_course_card .content {
  padding: 16px 0;
  flex: 1;
  width: 100%;
}
body main .course_dashboard .course_dashboard_router .dashboard_course_card .content h6 {
  color: #81a63d;
  font-size: 18px;
}
body main .course_dashboard .course_dashboard_router .appointment_grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 576px) {
  body main .course_dashboard .course_dashboard_router .appointment_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
body main .my-students {
  padding: 40px 0;
}
body main .my-students .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body main .my-students .header .title {
  height: 40px;
  margin: 0;
  flex-direction: row;
  gap: 24px;
}
body main .my-students .header .title h5 {
  color: #31535a;
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}
body main .my-students .header button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #81a63d;
  color: #fff;
  white-space: nowrap;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  border: 0;
  transition: 0.3s ease-in-out;
}
body main .my-students .header button i {
  font-size: 14px;
}
body main .my-students .header button:hover {
  background: #31535a;
}
body main .goal_details {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
body main .goal_details .inner_wrap {
  margin: 0 auto;
  width: min(100%, 500px);
}
body main .goal_details .inner_wrap .start {
  width: 100%;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .goal_details .inner_wrap .start .circle {
  width: auto;
  height: 60px;
  border-radius: 12px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body main .goal_details .inner_wrap .start .circle h5 {
  margin: 0;
  color: #81a63d;
  font-size: 22px;
}
body main .goal_details .inner_wrap .goal_steps {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: 0 auto;
  gap: 80px;
  padding: 0px 12px;
  margin-bottom: 80px;
}
body main .goal_details .inner_wrap .goal_steps li {
  align-items: center;
  gap: 16px;
  cursor: pointer;
  display: flex;
  position: relative;
}
body main .goal_details .inner_wrap .goal_steps li::after {
  width: 2px;
  z-index: -1;
  height: 200px;
  content: "";
  top: -6px;
  left: 158px;
  border: 2px dashed #31535a;
  position: absolute;
  transform: rotate(45deg);
}
body main .goal_details .inner_wrap .goal_steps li:nth-child(even) {
  padding-left: 80px;
  flex-direction: row-reverse;
}
body main .goal_details .inner_wrap .goal_steps li:nth-child(even) .content {
  flex-direction: row-reverse;
}
body main .goal_details .inner_wrap .goal_steps li:nth-child(even)::after {
  top: -16px;
  left: 160px;
  transform: rotate(-45deg);
}
@media screen and (max-width: 390px) {
  body main .goal_details .inner_wrap .goal_steps li:nth-child(even) {
    padding-left: 50px;
  }
  body main .goal_details .inner_wrap .goal_steps li:nth-child(even)::after {
    top: -16px;
    left: 130px;
  }
}
body main .goal_details .inner_wrap .goal_steps li:nth-child(odd) {
  padding-right: 80px;
  justify-content: start;
}
@media screen and (max-width: 390px) {
  body main .goal_details .inner_wrap .goal_steps li:nth-child(odd) {
    padding-right: 84px;
  }
  body main .goal_details .inner_wrap .goal_steps li:nth-child(odd)::after {
    top: -18px;
    left: 144px;
  }
}
@media screen and (max-width: 360px) {
  body main .goal_details .inner_wrap .goal_steps li:nth-child(odd) {
    padding-right: 60px;
  }
}
body main .goal_details .inner_wrap .goal_steps li.level .circle {
  width: 50px;
  height: 50px;
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efcb96;
}
body main .goal_details .inner_wrap .goal_steps li.level .circle img {
  width: 100%;
}
body main .goal_details .inner_wrap .goal_steps li.level .content {
  display: flex;
  flex-direction: column;
}
body main .goal_details .inner_wrap .goal_steps li.step .circle {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background: #31535a;
}
body main .goal_details .inner_wrap .goal_steps li.step .circle img {
  width: 100%;
}
body main .goal_details .inner_wrap .goal_steps li.step .content {
  display: flex;
  gap: 8px;
  max-width: 200px;
  align-items: center;
}
body main .goal_details .inner_wrap .goal_steps li.step .content img {
  height: 64px;
}
body main .goal_details .inner_wrap .goal_steps li.step .content h6 {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #000000 !important;
}
body main .goal_details .inner_wrap .goal_steps li.completed .circle {
  background: #81a63d;
}
body main .goal_details .inner_wrap .goal_steps li span {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}
body main .goal_details .inner_wrap .goal_end {
  position: relative;
  width: 100%;
  border-radius: 600px 600px 0 0;
  height: 250px;
  background: #81a63d;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 420px) {
  body main .goal_details .inner_wrap .goal_end {
    height: 190px;
  }
}
body main .goal_details .inner_wrap .goal_end h5 {
  margin: 0;
  transform: translateY(22px);
  font-size: 54px;
  color: #fff;
}
body main .goal_details .inner_wrap .goal_end::after {
  content: "";
  position: absolute;
  right: -10px;
  bottom: 0;
  height: 180px;
  background-size: cover;
  width: 84px;
  background-image: url("../images/ballon.png");
}
body main .goal_details .inner_wrap .goal_end::before {
  content: "";
  position: absolute;
  left: -10px;
  bottom: 0;
  height: 180px;
  background-size: cover;
  width: 84px;
  background-image: url("../images/ballon.png");
}
body main .sideMenu {
  padding: 16px;
  background: #fff;
  border-radius: 12px;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
}
body main .sideMenu .user_img {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 12px 8px;
  gap: 12px;
}
body main .sideMenu .user_img .img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #f2f2f2;
}
body main .sideMenu .user_img .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body main .sideMenu .user_img h6 {
  color: #31535a;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}
body main .sideMenu ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
body main .sideMenu ul li {
  width: 100%;
}
body main .sideMenu ul li a {
  width: 100%;
  color: #000000;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 600;
}
body main .sideMenu ul li a img {
  height: 18px;
}
body main .sideMenu ul li a i {
  color: #81a63d;
}
body main .sideMenu ul li a i.fa-user-xmark {
  color: red;
}
body main .sideMenu ul li a:hover, body main .sideMenu ul li a.active {
  background: #fff;
}
@media screen and (max-width: 768px) {
  body main .sideMenu {
    top: 100px;
    position: fixed;
    right: -50%;
    height: 100%;
    width: 50%;
    border-radius: 0;
    z-index: 999;
    padding: 16px 8px;
    background: #fff;
    transition: 0.3s ease-in-out;
    border-left: 1px solid #f2f2f2;
  }
  body main .sideMenu.open {
    right: 0;
  }
  body main .sideMenu ul li a:hover, body main .sideMenu ul li a.active {
    background: #f8f8f8;
  }
}
body main .open_menu {
  position: fixed;
  bottom: 24px;
  left: 24px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: none;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  background: #81a63d;
  color: #fff;
}
@media screen and (max-width: 768px) {
  body main .open_menu {
    display: flex;
  }
}
body .subscribe {
  padding: 40px 0;
}
body .subscribe .image {
  width: 100%;
  aspect-ratio: 3/2;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body .subscribe .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body .subscribe .benifits {
  margin-top: 16px;
}
body .subscribe .benifits h6 {
  font-size: 18px;
  color: #31535a;
  font-weight: 600;
}
body .subscribe .benifits ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 16px;
  color: #777777;
  line-height: 30px;
}
body .subscribe .benifits ul li::before {
  content: "\f648";
  font-family: "Font Awesome 6 pro";
  color: #81a63d;
}
body .subscribe .benifits ul.iconCheck li::before {
  content: "\f00c";
}
body .subscribe .course_header {
  margin-top: 24px;
}
body .subscribe .course_header h3 {
  color: #81a63d;
}
body .subscribe .course_header p {
  color: #777777;
  margin-bottom: 12px;
}
body .subscribe form label {
  color: #31535a !important;
  display: flex;
  align-items: center;
  gap: 4px;
}
body .subscribe form .time-group {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
body .subscribe form .time-group .duration_check {
  cursor: pointer;
}
@media (max-width: 576px) {
  body .subscribe form .time-group .duration_check {
    max-width: 100%;
  }
}
body .subscribe form .time-group .duration_check .time {
  min-width: 94px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f8f8f8;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  transition: 0.3s ease-in-out;
}
body .subscribe form .time-group .duration_check .time span {
  line-height: 20px;
}
@media (max-width: 576px) {
  body .subscribe form .time-group .duration_check .time {
    max-width: 100%;
  }
}
body .subscribe form .time-group .duration_check input {
  display: none;
}
body .subscribe form .time-group .duration_check input:checked + .time {
  background: #81a63d;
  border-color: #81a63d;
  color: #fff;
}
body .subscribe form h6 {
  color: #31535a;
  font-size: 16px;
  margin: 8px 0;
  font-weight: 600;
}
body .subscribe form ul {
  display: flex;
  flex-direction: column;
  width: min(100% - 24px, 400px);
}
body .subscribe form ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .subscribe form ul li label {
  cursor: pointer;
}
body .subscribe form ul li .check {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
body .subscribe form .save {
  margin-top: 0 !important;
  width: min(100% - 24px, 200px);
}
body .subscribe form .save.disabled {
  pointer-events: none;
  opacity: 0.5;
}
body .subscribe .total {
  width: min(100% - 24px, 400px);
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
body .subscribe .total .price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .subscribe .total .price h3,
body .subscribe .total .price span {
  color: #31535a;
  font-weight: 600;
  font-size: 20px;
}
body .subscribe .total .instalment_message {
  color: rgb(255, 0, 0);
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}
body .subscribe .total .installmentCheck {
  align-items: center;
}
body .subscribe .total .installmentCheck label {
  cursor: pointer;
}
body .subscribe .total .installmentCheck label h6 {
  margin: 0;
}
body .subscribe .total .installmentCheck input {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
body .subscribe .discount {
  color: #81a63d;
  font-size: 16px;
}
body .subscribe .check-field {
  display: flex;
  align-items: center;
  gap: 8px;
}
body .subscribe .check-field p {
  font-size: 14px;
  line-height: 20px;
}
body .subscribe .check-field a {
  line-height: 20px;
  color: #81a63d;
}
body footer {
  padding: 40px 8px 16px;
  border-top: 1px solid #f2f2f2;
}
body footer .img img {
  height: 120px;
}
body footer ul {
  display: flex;
  flex-wrap: wrap;
}
body footer ul li {
  padding-left: 16px;
}
body footer ul li a {
  color: #31535a;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}
body footer ul li a:hover {
  color: #81a63d;
}
body footer .about {
  padding-left: 62px;
}
@media (max-width: 576px) {
  body footer .about {
    padding: 0;
  }
}
body footer .about p {
  font-size: 14px;
  font-weight: 600;
  color: #31535a;
  line-height: 26px;
}
body footer .about .social {
  display: flex;
  margin-top: 16px;
  gap: 12px;
}
body footer .about .social li {
  padding: 0 !important;
}
body footer .about .social a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #81a63d;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
body footer .about .social a i {
  color: #fff;
}
body footer .about .social a img {
  height: 16px;
}
body footer .about .social a:hover {
  background: #31535a;
  transform: translateY(-4px);
}
body footer h3 {
  color: #31535a;
  font-weight: 600;
  font-size: 24px;
}
@media (max-width: 992px) {
  body footer h3 {
    margin-top: 32px;
  }
}
body footer .copy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}
body footer .copy p {
  color: #31535a;
  line-height: 26px;
}
body footer .copy p a {
  color: #81a63d;
}
body .form-ui {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
body .form-ui .input-field {
  width: 100%;
  display: flex;
  flex-direction: column;
}
body .form-ui .input-field .upload_wrapper {
  height: 256px;
  width: 100%;
  background: #f8f8f8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .form-ui .input-field .upload_wrapper label {
  cursor: pointer;
}
body .form-ui .input-field .upload_wrapper i {
  font-size: 32px;
  color: #81a63d;
}
body .form-ui .input-field .upload_wrapper input {
  display: none;
}
body .form-ui .input-field .upload_wrapper .img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .form-ui .input-field .upload_wrapper .img p {
  padding: 12px;
}
body .form-ui .input-field .upload_wrapper .img button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #81a63d;
  position: absolute;
  top: 12px;
  left: 12px;
}
body .form-ui .input-field .upload_wrapper .img button i {
  font-size: 16px;
  color: #fff;
}
body .form-ui .input-field .upload_wrapper .img img {
  width: 100%;
  height: 100%;
  padding: 12px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
body .form-ui .input-field .pass-group {
  display: flex;
}
body .form-ui .input-field .pass-group button {
  border: none;
  background: none;
  outline: none;
  width: 60px;
  height: 54px;
  border: 1px solid #f2f2f2;
  background: #f8f8f8;
  border-right: 0;
  border-radius: 12px 0 0 12px !important;
  color: #aaaaaa;
}
body .form-ui .input-field .pass-group input {
  border-left: 0 !important;
  border-radius: 0 12px 12px 0 !important;
}
body .form-ui .input-field input,
body .form-ui .input-field textarea,
body .form-ui .input-field select {
  width: 100%;
  height: 54px;
  direction: rtl;
  outline: none;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  background: #f8f8f8;
  padding: 12px 16px;
  font-size: 14px;
  color: #000000;
  box-shadow: none !important;
}
body .form-ui .input-field textarea {
  height: 152px;
}
body .form-ui .input-field label {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  gap: 4px;
  align-items: center;
  display: flex;
}
body .form-ui .phone-group .PhoneInput {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}
body .form-ui .phone-group .PhoneInput input {
  direction: ltr;
}
body .form-ui .phone-group .PhoneInput .PhoneInputCountryIcon {
  position: relative;
}
body .form-ui .phone-group .PhoneInput .PhoneInputCountryIconImg {
  width: 20px;
  position: absolute;
  top: -32px;
  left: 14px;
}
body .form-ui .phone-group .PhoneInput select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-align: left;
  padding: 12px 6px 12px 42px;
}
body .form-ui .phone-group .phone-code {
  height: 54px;
  border-radius: 4px;
}
body .form-ui .form_group {
  width: 100%;
  display: flex;
  gap: 8px;
}
body .form-ui .image-change-wrapper {
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 16px 4px;
}
body .form-ui .image-change-wrapper .img-wrap {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .form-ui .image-change-wrapper .img-wrap img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
body .form-ui .image-change-wrapper .upload input {
  display: none;
}
body .form-ui .image-change-wrapper .upload .plus {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #81a63d;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
body .form-ui .image-change-wrapper .upload .plus i {
  font-size: 14px;
  color: #fff;
}
body .form-ui .otp-container {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  direction: ltr !important;
}
@media (max-width: 576px) {
  body .form-ui .otp-container {
    gap: 8px;
  }
}
body .form-ui .otp-container .otp-input {
  border-radius: 12px;
  flex: 1;
  width: inherit;
  height: 80px !important;
  text-align: center;
  border: 1px solid #f2f2f2;
  background: #f8f8f8;
  font-size: 28px;
  font-weight: 600;
  color: #31535a !important;
  outline: none;
}
body .form-ui .otp-container .otp-input::-webkit-inner-spin-button {
  display: none;
}
@media (max-width: 576px) {
  body .form-ui .otp-container .otp-input {
    height: 60px !important;
  }
}
body .form-ui .save {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  background: #81a63d;
  color: #fff;
  margin-top: 12px;
}
body .modal-header button {
  margin: 0 auto 0 0 !important;
}
body .modal-header .back-btnNN {
  margin: 0 !important;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  border-radius: 50%;
  font-size: 14px;
  color: #fff;
  background-color: #81a63d;
}
body .modal-title {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}
body .gender_select {
  display: flex;
  flex-direction: column;
}
body .gender_select label {
  font-size: 14px;
}
body .gender_select .gender_card {
  display: flex;
  gap: 8px;
  background-color: #f8f8f8;
  color: #000000;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  padding: 12px 16px;
  transition: 0.3s;
  cursor: pointer;
  font-weight: 400 !important;
  font-size: 14px;
  width: 100%;
}
body .gender_select .gender_card img {
  height: 30px;
  width: 30px;
}
body .gender_select .gender_card:hover {
  background-color: #dce9c4;
}
body .gender_select .gender_card.active {
  border: 1px solid #81a63d;
  background-color: #dce9c4;
}
body .gender_select > div {
  display: flex;
  gap: 8px;
}
body .gender_select input {
  visibility: hidden;
}
body .gender_select label {
  font-weight: 500;
}
body .accordion-button {
  box-shadow: none !important;
  padding: 16px 16px;
}
body .accordion {
  border: none !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: none !important;
}
body .accordion-item {
  border: 1px solid #eeeeee !important;
  border-radius: 12px !important;
  overflow: auto;
}
body .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #81a63d;
}
body .accordion-button {
  text-align: start;
}
body .accordion-button .form-switch {
  display: flex;
  align-items: center;
  gap: 10px;
}
body .accordion-button .form-switch input {
  box-shadow: none !important;
}
body .accordion-button::after {
  content: "\f107";
  font-family: "Font Awesome 6 pro";
  background-image: none;
  transform: none !important;
  margin-left: 0;
  margin-right: auto;
}
body .accordion-button:not(.collapsed)::after {
  content: "\f106";
  background: none;
}
body .accordion-body {
  padding: 0 12px 12px;
}
body .accordion-body p {
  color: #777777;
  font-size: 16px;
}
body .accordion-body p a {
  color: #81a63d;
}
body.en {
  direction: ltr;
  overflow-x: hidden !important;
}
body.en .back i {
  transform: scaleX(-1) !important;
}
@media screen and (max-width: 768px) {
  body.en .sideMenu {
    right: initial;
    left: -50%;
    border-right: 1px solid #f2f2f2;
    border-left: 0;
  }
  body.en .sideMenu.open {
    left: 0;
    right: initial;
  }
}
body.en .open_menu {
  right: 24px;
  left: initial;
}
body.en header .drop_menu {
  padding: 8px 36px 8px 20px;
}
body.en .form-ui .input-field input {
  direction: ltr;
}
body.en .form-ui .input-field .pass-group button {
  border-radius: 0 12px 12px 0 !important;
  border-left: 0 !important;
  border-right: 1px solid #f2f2f2 !important;
}
body.en .form-ui .input-field .pass-group input {
  border-radius: 12px 0 0 12px !important;
  border-right: 0 !important;
  border-left: 1px solid #f2f2f2 !important;
}
body.en footer .about {
  padding-right: 62px;
  padding-left: 0;
}
body.en footer ul li {
  padding-left: 0;
  padding-right: 16px;
}
body.en main .acoustics .list-card .buttons {
  margin-left: auto;
  margin-right: 0;
}
body.en main .section_title a i {
  transform: translateY(2px) scale(-1);
  margin-left: 4px;
}
body.en main .course-card {
  direction: ltr !important;
}
body.en main .course-card .content a i {
  transform: scale(-1);
}
body.en main .courses .inner_search__form::before {
  right: unset;
  left: 16px;
}
body.en main .job_details .content .about_job ul {
  padding-right: 0;
  padding-left: 16px;
}
body.en main .terms_section ul {
  padding-right: 0;
  padding-left: 16px;
}
body.en main .custom-card .content a i {
  transform: scale(-1);
}
body.en main .custom-card {
  direction: ltr !important;
}
body.en main .what_we_offer table td.circled:before {
  right: initial;
  left: 8px;
}
body.en main .custom-card .label {
  left: initial;
  right: 8px;
}
body.en main .goal_details .inner_wrap .goal_steps li::after {
  transform: rotate(-45deg);
}
body.en main .goal_details .inner_wrap .goal_steps li:nth-child(even)::after {
  right: 160px;
  transform: rotate(45deg);
}
@media screen and (max-width: 390px) {
  body.en main .goal_details .inner_wrap .goal_steps li:nth-child(even) {
    padding-right: 50px;
  }
  body.en main .goal_details .inner_wrap .goal_steps li:nth-child(even)::after {
    top: -10px;
    left: 152px;
    transform: rotate(40deg);
  }
}
body.en main .goal_details .inner_wrap .goal_steps li:nth-child(odd) {
  padding-left: 80px;
  padding-right: 0;
}
@media screen and (max-width: 390px) {
  body.en main .goal_details .inner_wrap .goal_steps li:nth-child(odd) {
    padding-left: 84px;
  }
  body.en main .goal_details .inner_wrap .goal_steps li:nth-child(odd)::after {
    top: -10px;
    left: 152px;
    transform: rotate(-40deg);
  }
}
@media screen and (max-width: 360px) {
  body.en main .goal_details .inner_wrap .goal_steps li:nth-child(odd) {
    padding-left: 60px;
  }
}
body.en main .goal_details .inner_wrap .goal_steps li:nth-child(even) {
  padding-right: 80px;
  padding-left: 0;
}
body.en main .acoustics .swiper_pagination_title .swiper_pagination {
  flex-direction: row-reverse;
}
body.en .goal_card .icon i {
  transform: scale(-1);
}
body.en .accordion-button::after {
  margin-left: auto;
  margin-right: 0;
}
body.en .modal-header button {
  margin: 0 0 0 auto !important;
}
body.en .audio-card {
  direction: ltr !important;
}

.button-86 {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #fff;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-86::after, .button-86::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}
.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #81a63d;
  border-radius: 10px;
}
.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.0823529412);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}
.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}
.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}
.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.modal-header {
  border-bottom: 0;
}
.modal-header .btn-close {
  box-shadow: none !important;
  background-image: url("../images/close.svg");
}

.modal-body {
  padding: 0 12px 12px;
}
.modal-body video {
  width: 100%;
  height: 300px;
}

.Toastify__toast-body > div:last-child {
  line-height: 24px;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    right: 0;
    left: initial;
  }
}
.stars {
  display: flex;
  gap: 4px;
}
.stars li {
  color: #aaaaaa;
}
.stars li.star {
  color: #ffd500;
}
.stars img {
  height: 14px !important;
}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader i {
  font-size: 48px;
  color: #81a63d;
}

.hidden_audio {
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
  position: fixed;
  top: -999999px;
  left: -999999px;
}

.checkbox {
  width: 16px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  cursor: pointer;
}
.checkbox::after {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  content: "";
  font-family: "FontAwesome";
  width: 16px;
  height: 16px;
  border: 1px solid #777777;
  border-radius: 4px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.checkbox.error::after {
  border-color: red;
}
.checkbox:checked::after {
  content: "\f00c";
  background: #81a63d;
  color: #fff;
  border-color: #81a63d;
}

.form-switch input {
  box-shadow: none !important;
}
.form-switch input:checked {
  background-color: #81a63d !important;
  border-color: #81a63d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.wizard_tabs {
  border-bottom: 1px solid #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
}
.wizard_tabs .wizard_tab {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
  min-width: 130px;
}
.wizard_tabs .wizard_tab.active .num {
  background: #81a63d;
  color: #fff;
}
.wizard_tabs .wizard_tab.active .content h6 {
  color: #81a63d;
}
.wizard_tabs .wizard_tab .num {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background: #dce9c4;
  color: #000000;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.wizard_tabs .wizard_tab .content h6 {
  font-size: 16px;
  color: #777777;
  transition: 0.3s ease-in-out;
  line-height: 24px;
  margin: 0;
}
.wizard_tabs .wizard_tab .content p {
  font-size: 12px;
  color: #777777;
  line-height: 24px;
  margin: 0;
}

.subcribeModal .loginOrRegiester .title {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.subcribeModal .loginOrRegiester .title h3 {
  font-size: 22px;
  color: #000000;
  margin: 0;
}
.subcribeModal .loginOrRegiester .title p {
  color: #777777;
}
.subcribeModal .loginOrRegiester .title p span {
  color: #81a63d;
  cursor: pointer;
}
.subcribeModal .tabs {
  width: 100%;
  border-bottom: 2px solid #f2f2f2;
  margin-bottom: 12px;
}
.subcribeModal .tabs button {
  border: none;
  background: none;
  outline: none;
  padding: 0 12px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  color: #000000;
}
@media (max-width: 576px) {
  .subcribeModal .tabs button {
    font-size: 14px;
  }
}
.subcribeModal .tabs button::after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: #31535a;
  transition: 0.3s ease-in-out;
}
.subcribeModal .tabs button.active {
  color: #31535a;
}
.subcribeModal .tabs button.active::after {
  width: 100%;
}
.subcribeModal .forgot a {
  color: #81a63d;
  line-height: 24px;
}
.subcribeModal .pay_method {
  width: 100%;
  height: 146px;
}
@media screen and (max-width: 576px) {
  .subcribeModal .pay_method {
    height: 178px;
  }
}
.subcribeModal .pay_method .content {
  height: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  background: #f8f8f8;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #f2f2f2;
}
.subcribeModal .pay_method .content .icon_title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.subcribeModal .pay_method .content .icon_title img {
  height: 32px;
}
.subcribeModal .pay_method .content h6 {
  font-size: 20px;
  color: #81a63d;
  line-height: 24px;
  margin: 0;
}
.subcribeModal .pay_method .content p {
  font-size: 12px;
  color: #777777;
  line-height: 24px;
  margin: 0;
}
.subcribeModal .pay_method input {
  display: none;
}
.subcribeModal .pay_method input:checked + .content {
  background: rgba(129, 166, 61, 0.1411764706);
  border-color: #81a63d;
}
.subcribeModal .save_btn,
.subcribeModal .back_btn {
  height: 54px;
  border-radius: 12px;
  background: #81a63d;
  color: #fff;
}
.subcribeModal .back_btn {
  background: #dce9c4;
  color: #81a63d;
}
.subcribeModal .complete_process .subscribtion_info {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.subcribeModal .complete_process .subscribtion_info.active {
  max-width: 420px;
}
@media screen and (max-width: 768px) {
  .subcribeModal .complete_process .subscribtion_info.active {
    max-width: 100%;
  }
}
.subcribeModal .complete_process .subscribtion_info h4 {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
}
.subcribeModal .complete_process .subscribtion_info ul li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.subcribeModal .complete_process .subscribtion_info ul li b {
  font-weight: 400;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #81a63d;
}
@media (max-width: 768px) {
  .subcribeModal .complete_process .subscribtion_info ul li {
    width: 100%;
  }
}
.subcribeModal .complete_process .subscribtion_info .details {
  margin: 10px 0;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
.subcribeModal .complete_process .subscribtion_info .total {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media screen and (max-width: 768px) {
  .subcribeModal .complete_process .subscribtion_info .total {
    width: 100%;
  }
}
.subcribeModal .complete_process .subscribtion_info .total .price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subcribeModal .complete_process .subscribtion_info .total .price h3,
.subcribeModal .complete_process .subscribtion_info .total .price span {
  color: #31535a;
  font-weight: 600;
  font-size: 20px;
}
.subcribeModal .complete_process .subscribtion_info .total .instalment_message {
  color: rgb(255, 0, 0);
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}
.subcribeModal .complete_process .subscribtion_info .total .installmentCheck {
  align-items: center;
}
.subcribeModal .complete_process .subscribtion_info .total .installmentCheck label {
  cursor: pointer;
}
.subcribeModal .complete_process .subscribtion_info .total .installmentCheck label h6 {
  margin: 0;
}
.subcribeModal .complete_process .subscribtion_info .total .installmentCheck input {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content {
  align-items: flex-start;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content h2 {
  margin-bottom: 16px;
  font-size: 26px;
  color: #81a63d;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content ul {
  display: flex;
  gap: 8px;
  position: relative;
  flex-direction: column;
  margin-bottom: 12px;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  position: relative;
  font-size: 14px;
  color: #777777;
  line-height: 24px;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content ul li::before {
  content: "\f058";
  font-weight: 600;
  font-size: 16px;
  color: #81a63d;
  font-family: "Font Awesome 6 pro";
}
.subcribeModal .complete_process .subscribtion_info.libriry_content .price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content .price h5 {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #81a63d;
  margin: 0;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content .price h5 span {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  line-height: normal;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content .price p {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  line-height: normal;
  color: #777777;
  text-decoration: line-through;
}
.subcribeModal .complete_process .subscribtion_info.libriry_content .price p span {
  line-height: normal;
}
.subcribeModal .complete_process .upload_photo {
  flex: 2;
}
.subcribeModal .complete_process .upload_photo h6 {
  cursor: pointer;
}
.subcribeModal .complete_process .upload_photo h6.active {
  color: #81a63d;
}
.subcribeModal .complete_process .upload_photo .img {
  width: 100%;
  height: 256px;
  overflow: hidden;
  position: relative;
}
.subcribeModal .complete_process .upload_photo .img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/2;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.subcribeModal .complete_process .upload_photo .upload_wrapper {
  height: 256px;
  width: 100%;
  background: #f8f8f8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subcribeModal .complete_process .upload_photo .upload_wrapper label {
  cursor: pointer;
}
.subcribeModal .complete_process .upload_photo .upload_wrapper i {
  font-size: 32px;
  color: #81a63d;
}
.subcribeModal .complete_process .upload_photo .upload_wrapper input {
  display: none;
}
.subcribeModal .complete_process .upload_photo .upload_wrapper .img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.subcribeModal .complete_process .upload_photo .upload_wrapper .img button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #81a63d;
  position: absolute;
  top: 12px;
  left: 12px;
}
.subcribeModal .complete_process .upload_photo .upload_wrapper .img button i {
  font-size: 16px;
  color: #fff;
}
.subcribeModal .complete_process .upload_photo .upload_wrapper .img img {
  width: 100%;
  height: 100%;
  padding: 12px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

.complete_process_btn {
  width: 35%;
}
@media screen and (max-width: 576px) {
  .complete_process_btn {
    width: 40%;
  }
}

.payUserName {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payUserName span {
  line-height: inherit;
}

.add-student .form-ui {
  gap: 10px !important;
}
.add-student .form-ui textarea {
  height: 110px !important;
}

.students_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media screen and (max-width: 768px) {
  .students_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .students_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.student_card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 16px;
  border-radius: 12px;
  position: relative;
}
.student_card .img_name {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}
.student_card .img_name .img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.student_card .img_name .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.student_card .img_name .content h6 {
  color: #31535a;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}
.student_card .img_name .content p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 16px;
}
.student_card .img_name .content p span {
  color: #81a63d;
  font-weight: 600;
}
.student_card .contactNumber {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}
.student_card .contactNumber span {
  font-weight: 600;
  font-size: 14px;
  color: #81a63d;
}
.student_card button {
  width: 100%;
  transition: 0.3s ease-in-out;
  color: #fff;
  padding: 2px;
  font-size: 14px;
  border-radius: 4px;
}

.tableLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tableLoader .loader--style8 {
  width: 80px;
  height: 80px;
}
.tableLoader svg path,
.tableLoader svg rect {
  fill: #81a63d;
}

.appointments-modal .tabs {
  display: flex;
  border-bottom: 3px solid #f8f8f8;
}
.appointments-modal .tabs .tab {
  flex: 1;
  position: relative;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appointments-modal .tabs .tab h6 {
  color: #000000;
}
.appointments-modal .tabs .tab::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;
  height: 3px;
  width: 0;
  background: #81a63d;
  transition: 0.3s ease-in-out;
  transform: translateX(-50%);
}
.appointments-modal .tabs .tab.active::after {
  width: 100%;
}
.appointments-modal .tabs .tab.active h6 {
  color: #81a63d;
}
.appointments-modal .appontementheck {
  width: 100%;
  cursor: pointer;
}
.appointments-modal .appontementheck .content {
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 12px;
  background: #f8f8f8;
  gap: 16px;
}
.appointments-modal .appontementheck .content img {
  height: 48px;
}
.appointments-modal .appontementheck .content h4 {
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.appointments-modal .appontementheck input {
  display: none;
}
.appointments-modal .appontementheck input:checked + .content {
  border: 1px solid #81a63d;
  background: rgba(129, 166, 61, 0.1411764706);
}
.appointments-modal .appontementheck input:checked + .content h4 {
  color: #81a63d;
}
.appointments-modal .continue,
.appointments-modal .back {
  padding: 4px 24px;
  background: #81a63d;
  width: auto;
  height: auto;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  transition: 0.3s ease-in-out;
}
.appointments-modal .continue:hover,
.appointments-modal .back:hover {
  background: #31535a;
}
.appointments-modal .back {
  background: #dce9c4;
  color: #81a63d;
}
.appointments-modal .back:hover {
  background: #dce9c4;
}
.appointments-modal .timingRow {
  display: flex;
  gap: 8px;
}
.appointments-modal .slider_instructors {
  display: flex;
  gap: 8px;
  overflow: scroll;
}
.appointments-modal .slider_instructors::-webkit-scrollbar {
  display: none;
}
.appointments-modal .instructor_availabilty h6 {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
}
.appointments-modal .instructor_availabilty .availabilty_row {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}
.appointments-modal .instructor_availabilty .availabilty_row .day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 54px;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  background: #f8f8f8;
  padding: 12px 16px;
  font-size: 16px;
  color: #000000;
}

.instructor-card {
  min-width: 250px;
  max-width: 250px;
}
.instructor-card input {
  display: none;
}
.instructor-card input:checked + .content {
  border: 1px solid #81a63d;
  background: #f8f8f8;
}
.instructor-card .content {
  cursor: pointer;
  background: #fff;
  border: 1px solid #f2f2f2;
  padding: 16px;
  transition: 0.3s ease-in-out;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
}
.instructor-card .content .img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #f2f2f2;
}
.instructor-card .content .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.instructor-card .content h6 {
  color: #81a63d;
  font-size: 20px;
}
.instructor-card .content p {
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: #777777;
  margin: 0;
}
.instructor-card .content ul {
  width: 100%;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.instructor-card .content ul li {
  padding: 0 4px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: #000000;
  font-size: 16px;
}
.instructor-card .content ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 6 pro";
  font-weight: 900;
  color: #81a63d;
}

.editAppointmentModal .editappointment span {
  color: #81a63d;
}

.dropdown-item {
  text-align: initial;
}

.report_card {
  width: 100%;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.report_card .content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.report_card .content h6 {
  color: #000000;
  margin: 0;
}
.report_card .content p {
  margin: 0;
  line-height: 24px;
  font-size: 14px;
  color: #81a63d;
}
.report_card .pdf {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding: 12px 8px 8px;
  cursor: pointer;
  height: 90%;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}
.report_card .pdf img {
  height: 50px;
}
.report_card .pdf span {
  color: #81a63d;
  font-size: 14px;
  line-height: 12px;
}

.row_header h6 {
  color: #31535a;
  font-weight: 600;
  font-size: 18px;
}

.assignment_card {
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 22px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.assignment_card .content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.assignment_card .content h6 {
  color: #81a63d;
  margin: 0;
  font-size: 18px;
}
.assignment_card .content p {
  margin: 0;
  line-height: 24px;
  font-size: 14px;
  color: #777777;
  margin-bottom: auto;
}
.assignment_card .content .date {
  font-size: 14px;
  color: #81a63d;
  margin: 0;
}
.assignment_card .content .img_name {
  display: flex;
  align-items: center;
  gap: 8px;
}
.assignment_card .content .img_name .img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
  overflow: hidden;
}
.assignment_card .content .img_name .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.assignment_card .content .img_name h6 {
  color: #000000;
  margin: 0;
  font-size: 16px;
}
.assignment_card .student {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
.assignment_card .student .img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
  overflow: hidden;
}
.assignment_card .student .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.assignment_card .student h6 {
  font-size: 12px;
  color: #000000;
  white-space: nowrap;
  font-weight: 600;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.name_img {
  display: flex;
  align-items: center;
  gap: 8px;
}
.name_img .img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
  overflow: hidden;
}
.name_img .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.name_img h6 {
  color: #000000;
  margin: 0;
  font-size: 14px;
}

.voice_field {
  display: flex;
  align-items: center;
  gap: 16px;
}
.voice_field .mic_btn {
  min-width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 4px;
}
.voice_field .mic_btn img {
  height: 20px;
}
.voice_field .uploaded_voice {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}
.voice_field .uploaded_voice audio {
  width: 85%;
}
.voice_field .uploaded_voice button {
  background: red;
  color: #fff;
  border-radius: 50%;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.voice_field .uploaded_voice button i {
  font-size: 12px;
}

.appointment_card {
  background: #f8f8f8;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.appointment_card h5 {
  margin: 4px 0;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.appointment_card .cancel {
  background: red;
  width: 100%;
  color: #fff;
  border-radius: 4px;
}
.appointment_card .edit {
  background: #81a63d;
  width: 100%;
  color: #fff;
  border-radius: 4px;
}
.appointment_card .countDownTimer {
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.appointment_card .countDownTimer .block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
}
.appointment_card .countDownTimer .block span {
  font-size: 12px;
  color: #000000;
  font-weight: 600;
}
.appointment_card .countDownTimer .block .count {
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #81a63d;
  color: #fff;
  padding: 4px 0;
  font-weight: 400;
  font-size: 16px;
  border-radius: 4px;
}
.appointment_card button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #31535a;
  color: #fff;
  border-radius: 4px;
}
.appointment_card button.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

@media screen and (max-width: 992px) {
  .hide_sm {
    display: none;
  }
}

.hide_lg {
  display: none;
}
@media screen and (max-width: 992px) {
  .hide_lg {
    display: block;
  }
}

.exeptional {
  background: #f8f8f8;
  position: relative;
}

.exeptional_text {
  background: #f8f8f8;
}
.exeptional_text td {
  text-align: center;
}

.exiptionalCancel {
  background: #31535a;
  color: #fff;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 12px;
}

.goal_card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  gap: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.goal_card h6 {
  color: #81a63d;
  font-size: 20px;
}
.goal_card p {
  margin: 0;
  font-size: 14px;
  color: #31535a;
  line-height: 24px;
}
.goal_card .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}
.goal_card .icon i {
  color: #81a63d;
}

.choose_student {
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.choose_student .student_check {
  cursor: pointer;
}
.choose_student .student_check input {
  display: none;
}
.choose_student .student_check input:checked + .content .img {
  border-color: #81a63d;
}
.choose_student .student_check input:checked + .content p {
  color: #81a63d;
}
.choose_student .student_check .content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}
.choose_student .student_check .content .img {
  padding: 2px;
  width: 64px;
  height: 64px;
  transition: 0.3s ease-in-out;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #f2f2f2;
}
.choose_student .student_check .content .img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.choose_student .student_check .content p {
  margin: 0;
  color: #31535a;
}

.studnet_answer {
  color: #81a63d;
  font-size: 22px;
  margin-bottom: 12px;
}

.answer_field {
  width: 100%;
  padding: 12px;
  background: #f8f8f8;
  color: #000000;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
}
.answer_field p {
  margin: 0;
}

.voice_answer {
  width: 100%;
  padding: 8px 0;
  margin-bottom: 30px;
}
.voice_answer audio {
  width: 100%;
}

.my-subscriptions {
  padding: 40px 0;
}
.my-subscriptions .librarySubLink {
  margin-bottom: 24px;
  width: 100%;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: #31535a;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-subscriptions .librarySubLink a {
  color: #fff;
  text-align: center;
}
.my-subscriptions .section_title {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
}
.my-subscriptions .section_title .img img {
  height: 36px;
}
.my-subscriptions .section_title h2 {
  color: #31535a;
  font-size: 18px;
  margin: 0;
}
.my-subscriptions .buttons {
  margin-top: 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-subscriptions .buttons button {
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  background: #81a63d;
  color: #fff;
}
.my-subscriptions .buttons button.cancel {
  background: red;
}
.my-subscriptions .buttons button.upgrade {
  background: #31535a;
}

.card_btns {
  display: flex;
  position: absolute;
  left: 8px;
  bottom: 8px;
  gap: 8px;
}
.card_btns button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #f8f8f8;
  border-radius: 50%;
}
.card_btns button i {
  color: #81a63d;
}
.card_btns button:nth-child(2) i {
  color: red;
}

.confirm-delete p {
  color: #31535a;
  font-size: 16px;
}
.confirm-delete .cancel-btn {
  border: 1px solid #000000;
  color: #000000;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
}
.confirm-delete .delete-btn {
  width: 100%;
  padding: 4px;
  background: red;
  color: #fff;
  border-radius: 8px;
}

.add-discount {
  width: min(100%, 200px);
  height: 54px;
  border-radius: 12px;
  background: #81a63d;
  color: #fff;
}

.save_bttn {
  width: 100%;
  padding: 4px;
  background: #31535a;
  color: #fff;
  border-radius: 8px;
}

.complaints {
  padding: 16px 0;
}
.complaints .title {
  gap: 12px;
}
.complaints .title h2 {
  color: #31535a;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.complaints button {
  background: #81a63d;
  width: 100%;
  color: #fff;
  padding: 8px;
  margin-top: 4px;
  border-radius: 12px;
}
@media screen and (max-width: 576px) {
  .complaints .form_group {
    flex-direction: column;
  }
}

.popup-modal {
  padding: 0 24px 16px;
}
.popup-modal ul li {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  color: #777777;
  line-height: 28px;
}
.popup-modal ul li::before {
  content: "\f648";
  font-family: "Font Awesome 6 pro";
  color: #81a63d;
}
.popup-modal ul.iconCheck li::before {
  content: "\f00c";
}
.popup-modal input[type=checkbox] {
  width: 16px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  cursor: pointer;
}
.popup-modal input[type=checkbox]::after {
  position: absolute;
  right: 50%;
  transform: translate(50%, -50%);
  top: 50%;
  content: "";
  font-family: "FontAwesome";
  width: 16px;
  height: 16px;
  border: 1px solid #aaaaaa;
  border-radius: 2px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #31535a;
}
.popup-modal input[type=checkbox]:checked::after {
  content: "\f00c";
  background: #31535a;
  color: #fff;
  border-color: #31535a;
}

.completed {
  color: #81a63d !important;
}

.error {
  font-size: 14px;
  color: red;
}

.page_title {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #f8f8f8;
  border-radius: 8px;
  gap: 12px;
  margin-bottom: 48px;
}
.page_title h5 {
  color: #31535a;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.page_title .buttons button {
  background: #81a63d;
  color: #fff;
  border-radius: 4px;
  padding: 0 12px;
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #81a63d;
  border-radius: 50%;
}
.back i {
  font-size: 14px;
  color: #fff;
}

.rhap_container {
  direction: ltr !important;
}

.rhap_progress-indicator {
  width: 16px;
  height: 16px;
  top: -6px;
  box-shadow: none;
  background: #81a63d;
}

.rhap_progress-filled {
  background: #81a63d;
}

.rhap_time {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.rhap_button-clear svg path {
  fill: #81a63d;
}
.rhap_button-clear svg {
  width: 24px;
}
.rhap_button-clear.rhap_play-pause-button svg {
  width: 36px;
}

.rhap_container {
  box-shadow: none !important;
  border: none !important;
  padding-top: 4px !important;
}

.rhap_download-progress {
  background: #f2f2f2;
}

.rhap_volume-bar {
  background: #f2f2f2;
}

.rhap_volume-indicator {
  background: #81a63d;
}

.close_player {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px;
}
.close_player i {
  font-size: 18px;
  color: #81a63d;
  cursor: pointer;
}

.audio_player_container {
  position: sticky;
  bottom: 0;
  z-index: 9;
}

.audio_title {
  background: #fff;
  color: #31535a;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 0 0;
  border-top: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.addAudioToPlayList button {
  background: #31535a;
  color: #fff;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 4px 24px;
  margin-top: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.addAudioToPlayList .noPlayLists {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.addAudioToPlayList .noPlayLists h6 {
  font-size: 32px;
  color: #aaaaaa;
}
.addAudioToPlayList .save {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  background: #81a63d;
  color: #fff;
}
.addAudioToPlayList .list_check_box {
  width: 100%;
}
.addAudioToPlayList .list_check_box input {
  display: none;
}
.addAudioToPlayList .list_check_box input:checked ~ .content {
  border: 2px solid #81a63d;
}
.addAudioToPlayList .list_check_box input:checked ~ .content::after {
  content: "\f058";
  color: #81a63d;
}
.addAudioToPlayList .list_check_box .content {
  cursor: pointer;
  padding: 12px;
  display: flex;
  border-radius: 12px;
  border: 2px solid #f2f2f2;
  transition: 0.3s ease-in-out;
  gap: 8px;
  position: relative;
}
.addAudioToPlayList .list_check_box .content::after {
  position: absolute;
  top: 0;
  left: 4px;
  content: "";
  font-family: "Font Awesome 6 pro";
}
.addAudioToPlayList .list_check_box .content .icon {
  width: 80px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-radius: 8px;
}
.addAudioToPlayList .list_check_box .content .icon img {
  height: 36px;
}
.addAudioToPlayList .list_check_box .content .contentt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0;
  gap: 8px;
  padding: 4px 0;
}
.addAudioToPlayList .list_check_box .content .contentt h6 {
  color: #000000;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}
.addAudioToPlayList .list_check_box .content .contentt p {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  line-height: normal;
  font-size: 14px;
  color: #777777;
}
.addAudioToPlayList .list_check_box .content .contentt p img {
  height: 16px;
}
.addAudioToPlayList .list_check_box .content .buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-right: auto;
  color: #81a63d;
}
.addAudioToPlayList .list_check_box .content .buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  color: #81a63d;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 14px;
}
.addAudioToPlayList .list_check_box .content .buttons a:nth-child(2) {
  color: red;
}

.MuiInputBase-input {
  padding: 0 !important;
  height: auto !important;
  border: 0 !important;
  background: none !important;
}

.MuiFormControl-root {
  height: 54px;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  display: flex;
  background: #f8f8f8;
  align-items: center;
  justify-content: center;
}
.MuiFormControl-root fieldset {
  display: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none !important;
}

.MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100% !important;
}

.join_session {
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
}
.join_session iframe {
  width: 100%;
  height: 100%;
}
.join_session a {
  top: 16px;
  right: 16px;
  background: #fff;
  color: red;
  padding: 8px 24px;
  border-radius: 8px;
  position: absolute;
  font-size: 16px;
}

.course_plans {
  padding: 60px 0;
}
.course_plans .title {
  text-align: center;
  color: #31535a;
  font-size: 32px;
  margin-bottom: 32px;
  position: relative;
}
.course_plans .title::after {
  position: absolute;
  content: "";
  top: 45px;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: #81a63d;
}
.course_plans .plan {
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.course_plans .plan .convertion {
  color: red;
  line-height: normal;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
.course_plans .plan .planHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
.course_plans .plan .planHeader h3 {
  font-size: 28px;
  font-size: 600;
  margin: 0;
  text-align: center;
  color: #000000;
}
.course_plans .plan .planHeader .price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.course_plans .plan .planHeader .price h5 {
  font-size: 36px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #81a63d;
  margin: 0;
}
.course_plans .plan .planHeader .price h5 span {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  line-height: normal;
}
.course_plans .plan .planHeader .price p {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  line-height: normal;
  color: #777777;
  text-decoration: line-through;
}
.course_plans .plan .planHeader .price p span {
  line-height: normal;
}
.course_plans .plan .planHeader .label {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  padding: 6px 16px;
  border-radius: 20px;
  width: -moz-fit-content;
  width: fit-content;
}
.course_plans .plan .planHeader .label h6 {
  margin: 0;
  font-size: 14px;
  color: #000000;
}
.course_plans .plan ul {
  display: flex;
  gap: 8px;
  position: relative;
  flex-direction: column;
  margin-bottom: 12px;
}
.course_plans .plan ul li {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  font-size: 14px;
  color: #777777;
  line-height: 24px;
}
.course_plans .plan ul li::before {
  content: "\f058";
  font-weight: 600;
  font-size: 16px;
  color: #81a63d;
  font-family: "Font Awesome 6 pro";
}
.course_plans .plan .line {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background: #f2f2f2;
}
.course_plans .plan .price_subscribe {
  display: flex;
  padding-top: 16px;
}
.course_plans .plan .price_subscribe button {
  background: #81a63d;
  color: #fff;
  width: 100%;
  font-size: 16px;
  padding: 4px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course_plans .plan.active {
  border: 2px solid #81a63d;
  box-shadow: none;
}

.roadmap {
  padding: 40px 0;
}
.roadmap .title {
  text-align: center;
  color: #31535a;
  font-size: 32px;
  margin-bottom: 32px;
  position: relative;
}
.roadmap .title::after {
  position: absolute;
  content: "";
  top: 45px;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: #81a63d;
}
.roadmap .pathGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  position: relative;
}
@media screen and (max-width: 576px) {
  .roadmap .pathGrid {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 24px 0 36px;
  }
}
.roadmap .pathGrid::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0;
  border-bottom: 2px dashed #31535a;
}
@media screen and (max-width: 576px) {
  .roadmap .pathGrid::after {
    height: 100%;
    width: 1px;
    left: 16px;
    border-bottom: 0;
    border-left: 2px dashed #31535a;
  }
}
.roadmap .pathGrid .path {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (max-width: 576px) {
  .roadmap .pathGrid .path {
    flex-direction: row;
  }
}
.roadmap .pathGrid .path::after {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 18px;
  height: 18px;
  background: #31535a;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 576px) {
  .roadmap .pathGrid .path::after {
    left: -28px;
    top: 50%;
    bottom: initial;
    transform: translateY(-50%);
  }
}
.roadmap .pathGrid .path .flipCard {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  perspective: 1000px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media screen and (max-width: 576px) {
  .roadmap .pathGrid .path .flipCard {
    height: 180px;
    flex-shrink: initial;
  }
}
.roadmap .pathGrid .path .flipCard .cardFront {
  width: 100%;
  height: 100%;
  padding: 24px;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-size: cover !important;
  background-position: top !important;
  background-repeat: no-repeat !important;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.roadmap .pathGrid .path .flipCard .cardFront .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  gap: 4px;
}
.roadmap .pathGrid .path .flipCard .cardFront .content .job {
  color: #fff;
  font-size: 12px;
}
.roadmap .pathGrid .path .flipCard .cardFront .content .title {
  font-size: 18px;
  color: #fff;
}
.roadmap .pathGrid .path .flipCard .cardFront .time {
  display: flex;
  gap: 24px;
}
.roadmap .pathGrid .path .flipCard .cardFront .time p {
  color: #fff;
  font-size: 14px;
}
.roadmap .pathGrid .path .flipCard .cardFront .time p i {
  margin-left: 4px;
}
.roadmap .pathGrid .path .flipCard .cardBack {
  padding: 20px;
  position: absolute;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
}
.roadmap .pathGrid .path .flipCard .cardBack .content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.roadmap .pathGrid .path .flipCard .cardBack .content .sponser {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.roadmap .pathGrid .path .flipCard .cardBack .content img {
  height: 32px;
}
.roadmap .pathGrid .path .flipCard .cardBack .content h2 {
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 0;
  color: #31535a;
  font-weight: 600;
}
.roadmap .pathGrid .path .flipCard .cardBack .content h2 img {
  height: 24px;
  margin-left: 8px;
}
.roadmap .pathGrid .path .flipCard .cardBack .content p {
  font-size: 14px;
  line-height: 24px;
  color: #777777;
  margin: 0;
}
.roadmap .pathGrid .path .flipCard .cardBack .content h6 {
  margin: 0;
  font-weight: 600;
  color: #31535a;
}
.roadmap .pathGrid .path .flipCard .cardBack .content ol {
  padding: 0 16px 0 0;
  margin: 0;
}
.roadmap .pathGrid .path .flipCard .cardBack .content ol li {
  font-size: 14px;
  line-height: 24px;
  color: #777777;
  margin: 0;
}
.roadmap .pathGrid .path .flipCard .cardBack .content .btns {
  display: flex;
  gap: 32px;
}
.roadmap .pathGrid .path .flipCard:hover {
  z-index: 2;
}
.roadmap .pathGrid .path .flipCard:hover .cardFront {
  transform: rotateY(-180deg);
}
.roadmap .pathGrid .path .flipCard:hover .cardBack {
  transform: rotateY(0deg);
}
.roadmap .pathGrid .path .path_title {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
  justify-content: center;
  white-space: nowrap;
}
.roadmap .pathGrid .path .path_title h3 {
  font-size: 20px;
  color: #000000;
}
.roadmap .pathGrid .path .path_title h5 {
  font-size: 14px;
  color: #777777;
}
@media screen and (max-width: 576px) {
  .roadmap .pathGrid .path .path_title {
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  body.en .roadmap .pathGrid {
    padding: 0 36px 0 24px;
  }
  body.en .roadmap .pathGrid::after {
    left: initial;
    right: 16px;
  }
  body.en .roadmap .pathGrid .path::after {
    right: -28px;
    left: initial;
  }
}/*# sourceMappingURL=global.css.map */