/*+++++++++ fonts +++++++++*/
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "din";
  src: url("../webfonts/din-next-lt-w23-regular.ttf");
}
/*+++++++++ mixins +++++++++*/
@mixin centerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin clearInput {
  border: none;
  background: none;
  outline: none;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

/*+++++++++ global +++++++++*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 32px;
  scroll-behavior: smooth;
  font-family: "din", sans-serif;
  -webkit-tap-highlight-color: transparent;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
button {
  border: none;
  background-color: unset;
}
::-webkit-scrollbar {
  width: 4px !important;
}
::-webkit-scrollbar-track {
  background-color: #fff !important;
}
::-webkit-scrollbar-thumb {
  background: #eee !important;
  height: 300px !important;
}
:target {
  scroll-margin-top: 100px;
}
/*+++++++++ colors +++++++++*/
$main: #31535a;
$secondary: #81a63d;
$second20: #dce9c4;
$second10: #81a63d24;
$border: #dddddd;
$inputBG: #f8f8f8;
$inputHint: #aaaaaa;
$gray: #777777;
$lightGray: #eeeeee;
$white: #fff;
$inputStroke: #f2f2f2;
$gold: #ffd500;
$dark: #000000;
$mainShadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
$lightShadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$transation: 0.3s ease-in-out;

body {
  direction: rtl;
  position: relative;
  overflow-x: hidden;
  header {
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    z-index: 999;
    position: absolute;
    border-bottom: 1px solid $inputStroke;
    background: $white;
    transition: position 0.3s ease-in-out;
    &.sticky {
      box-shadow: $lightShadow;
      position: fixed;
      border-color: transparent;
      animation: fadeInDown 0.5s ease-in-out forwards;
    }
    nav {
      display: flex;
      padding: 0;
      height: 100%;
      .logo {
        flex: 1;
        gap: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          height: 80px;
        }
      }
      .nav_links {
        flex: 1;
        @include centerFlex();
        ul {
          height: 100%;
          display: flex;
          li {
            height: 100%;
            position: relative;
            @include centerFlex();
            padding: 0 16px;
            a {
              font-size: 16px;
              color: $main;
              transition: 0.2s ease-in-out;
              &.active,
              &:hover {
                color: $secondary;
              }
            }
            .drop {
              font-size: 16px;
              color: $main;
              cursor: pointer;
              position: relative;
            }
            &.hide-lg {
              display: none;
            }
          }
        }
        @media (max-width: 992px) {
          background: $white;
          margin-top: 100px;
          height: calc(100vh - 100px);
          position: absolute;
          right: -100%;
          width: 100%;
          transition: $transation;
          ul {
            flex-direction: column;
            justify-content: center;
            li {
              height: fit-content;
              padding: 8px 0;
              &.hide-lg {
                display: flex;
              }
            }
          }
          &.active {
            right: 0;
          }
        }
      }
      .utils {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        ul {
          height: 100%;
          display: flex;
          li {
            position: relative;
            height: 100%;
            @include centerFlex();
            padding: 0 6px;
            a {
              color: $main;
            }
            .profile {
              cursor: pointer;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .lang_toggler {
              border-radius: 10px;
              color: $secondary;
              gap: 8px;
              border: 1px solid $secondary;
              @include centerFlex();
              cursor: pointer;
              padding: 2px 12px;
              img {
                height: 16px;
              }
            }
            .menu-btn {
              cursor: pointer;
              width: 30px;
              height: 30px;
              display: none;
              z-index: 2;
              flex: 1;
              @media (max-width: 992px) {
                @include centerFlex();
                justify-content: flex-end;
              }
              .menu-bar {
                position: relative;
                height: 2px;
                width: 28px;
                background-color: $secondary;
                transition: 0.5s ease;
                &::before {
                  content: "";
                  display: block;
                  position: absolute;
                  top: -9px;
                  left: 0;
                  height: 2px;
                  width: 100%;
                  background-color: $secondary;
                  transition: 0.5s ease;
                }
                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  bottom: -9px;
                  left: 0;
                  height: 2px;
                  width: 100%;
                  background-color: $secondary;
                  transition: 0.5s ease;
                }
                &.active {
                  background-color: transparent;
                  transform: rotate(90deg);
                  &::before {
                    -webkit-transform: translate(0px, 10px) rotate(135deg);
                    transform: translate(0px, 9px) rotate(135deg);
                  }
                  &::after {
                    -webkit-transform: translate(0px, -10px) rotate(-135deg);
                    transform: translate(0px, -9px) rotate(-135deg);
                  }
                }
              }
            }
          }
          .hide-sm {
            @media (max-width: 992px) {
              display: none !important;
            }
          }
        }
      }
    }
    .drop_menu {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translateX(-50%);
      background: $white;
      padding: 12px 24px;
      border-radius: 8px;
      box-shadow: $lightShadow;
      ul {
        flex-direction: column;
        .nav_link {
          padding: 0;
          white-space: nowrap;
          justify-content: flex-start;
          a {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            i {
              color: $secondary;
            }
          }
        }
      }
    }
  }
  main {
    padding-top: 100px;
    min-height: calc(100vh - 100px);
    .auth {
      background-image: url(../images/auth-bg.jpg);
      background-size: cover;
      background-position: center;
      padding: 40px 0;
      @include centerFlex();
      .form_wrapper {
        width: min(100% - 32px, 1042px);
        min-height: 460px;
        background: $white;
        border-radius: 24px;
        box-shadow: $mainShadow;
        display: flex;
        overflow: hidden;
        @media (max-width: 992px) {
          flex-direction: column;
        }
        .form_container {
          width: 50%;
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          .logo {
            width: 100%;
            @include centerFlex();
            img {
              height: 100px;
            }
          }
          .welcome {
            h4 {
              color: $dark;
              font-size: 28px;
              font-weight: 600;
            }
            p {
              color: $gray;
              margin-bottom: 0;
            }
          }
          .forgot a {
            color: $main;
            transition: $transation;
            &:hover {
              color: $secondary;
            }
          }
          .continue {
            line-height: 26px;
            margin-bottom: 0;
            font-size: 16px;
            color: $main;
            a {
              line-height: inherit;
              color: $secondary;
            }
          }
          .reset_otp_title {
            padding: 0 16px;
            text-align: center;
            margin-top: 24px;
            p {
              font-size: 18px;
              color: $main;
              margin-bottom: 0;
              font-weight: 500;
              cursor: pointer;
              span {
                color: $secondary;
              }
            }
          }
          .resend {
            @include space-between();
            color: $dark;
            font-size: 16px;
            p {
              margin-bottom: 0;
            }
            .counterDown {
              font-weight: 600;
            }
          }
          .tabs {
            width: 100%;
            border-bottom: 2px solid $inputStroke;
            margin-bottom: 12px;
            button {
              @include clearInput();
              padding: 0 12px;
              position: relative;
              font-size: 16px;
              font-weight: 500;
              transition: $transation;
              color: $dark;
              @media (max-width: 576px) {
                font-size: 14px;
              }
              &::after {
                content: "";
                position: absolute;
                bottom: -2px;
                width: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 2px;
                background: $main;
                transition: $transation;
              }
              &.active {
                color: $main;
                &::after {
                  width: 100%;
                }
              }
            }
          }
          @media (max-width: 992px) {
            width: 100%;
          }
        }
        .page_preif {
          width: 50%;
          background-image: linear-gradient(#000000d6, #000000ce),
            url(../images/auth_thumb.jpeg);
          background-size: cover;
          background-position: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 64px 32px;
          .content {
            z-index: 2;
            .circle {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              background: $white;
            }
            h1 {
              margin-top: 12px;
              color: $white;
              font-size: 48px;
              margin-bottom: 16px;
              @media (max-width: 576px) {
                font-size: 36px;
              }
            }
            p {
              color: $white;
              font-size: 18px;
              margin-bottom: 4px;
              @media (max-width: 576px) {
                font-size: 14px;
                line-height: 24px;
              }
            }
            h6 {
              font-size: 18px;
              color: $white;
              a {
                color: $secondary;
              }
              @media (max-width: 576px) {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
          @media (max-width: 992px) {
            width: 100%;
          }
        }
      }
    }
    .hero_section {
      padding: 40px 0;
      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
          font-size: 48px;
          color: $secondary;
          margin-bottom: 16px;
          @media (max-width: 576px) {
            font-size: 32px;
          }
        }
        h6 {
          color: $main;
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 12px;
        }
        ul {
          color: $gray;
          margin-bottom: 16px;
          li strong {
            font-weight: 400;
          }
          li {
            display: flex;
            align-items: center;
            gap: 8px;
            &::before {
              content: "";
              width: 16px;
              height: 16px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              border-radius: 50%;
              background-image: url("../images/starHero.svg");
            }
          }
        }
      }
    }
    .title {
      @include centerFlex();
      flex-direction: column;
      margin-bottom: 16px;
      h1 {
        font-size: 24px;
        font-weight: 600;
        color: $dark;
      }
      p {
        color: $main;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
      }
      h3 {
        font-size: 20px;
        color: $dark;
        text-align: center;
      }
    }
    .section-header {
      width: 100%;
      height: 140px;
      background: linear-gradient(
          0deg,
          rgba(129, 166, 61, 0.77),
          rgba(129, 166, 61, 0.875)
        ),
        url("../images/trial.png");
      // background: linear-gradient(90.24deg, #69b1c0 0.21%, #31535a 55.07%);
      background-size: cover;
      background-position: 50% 50%;
      .container {
        @include space-between();
        height: 100%;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          h1 {
            color: $white;
            margin-bottom: 4px;
          }
          p {
            margin: 0;
            color: $white;
            a {
              color: $white;
            }
          }
        }
        .thunb {
          height: 120px;
          width: 120px;
          border-radius: 50%;
          background: $white;
          @include centerFlex();
          img {
            height: 90%;
          }
          @media (max-width: 576px) {
            display: none;
          }
        }
      }
    }
    .courses {
      padding: 40px 0;
      .inner_search__form {
        width: 100%;
        margin-bottom: 12px;
        position: relative;
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
          color: $secondary;
          content: "\f002";
          font-family: "Font Awesome 6 pro";
        }
        input {
          width: 100%;
          height: 56px;
          background: $inputBG;
          padding: 0 48px 0;
          outline: none;
          border-radius: 8px;
          border: 1px solid $inputStroke;
          font-size: 14px;
        }
      }
    }
    .custom-card {
      direction: rtl;
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: $lightShadow;
      .label {
        position: absolute;
        top: 8px;
        left: 8px;
        background: $secondary;
        color: $white;
        font-size: 14px;
        padding: 0px 16px;
        border-radius: 4px;
      }
      h6 {
        font-size: 14px;
        color: $main;
        font-weight: 600;
        margin-top: 4px;
      }
      .img {
        width: 100%;
        aspect-ratio: 3 / 2;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .content {
        padding: 16px;
        h4 {
          font-size: 24px;
          color: $secondary;
          &.smallhead {
            font-size: 20px;
          }
        }
        a {
          width: 28px;
          font-size: 14px;
          height: 28px;
          background: $secondary;
          color: $white;
          @include centerFlex();
          border-radius: 50%;
        }
        p {
          margin: 0;
          font-weight: 500;
          color: $main;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          gap: 8px;
          align-items: center;
          img {
            height: 16px;
          }
        }
        .likes_container {
          display: flex;
          gap: 16px;
          span {
            color: $dark;
            line-height: normal;
          }
          .dislikes {
            display: flex;
            align-items: center;
            gap: 6px;
            button {
              border-radius: 50%;
              @include centerFlex();
              color: #cecece;
              font-size: 16px;
              &.active {
                color: red !important;
              }
            }
          }
          .likes {
            display: flex;
            align-items: center;
            gap: 6px;
            button {
              border-radius: 50%;
              @include centerFlex();
              color: #cecece;
              font-size: 16px;
              &.active {
                color: $secondary !important;
              }
            }
          }
          &.disabled {
            pointer-events: none;
          }
        }
      }
      &.longImg {
        .img {
          img {
            aspect-ratio: 3 / 2;
          }
        }
        .content {
          h4 {
            margin: 0;
            font-size: 18px;
            @media (max-width: 576px) {
              font-size: 16px;
            }
          }
        }
      }
      .blur_overLay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        background: rgb(0 0 0 / 73%);
        pointer-events: none;
        @include centerFlex();
        flex-direction: column;
        gap: 12px;
        img {
          height: 52px;
        }
        p {
          color: $white;
          margin: 0;
          font-weight: 600;
        }
      }
      .sno {
        @include space-between();
        b {
          display: flex;
          align-items: center;
          gap: 4px;
          line-height: 28px;
          font-size: 14px;
          i {
            font-size: 14px;
            color: $secondary;
          }
        }
        span {
          background: $secondary;
          color: $white;
          padding: 0px 10px;
          font-size: 14px;
          line-height: 25px;
          border-radius: 2px;
        }
      }
    }
    .audio-card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: $lightShadow;
      .img {
        position: relative;
        width: 100%;
        img {
          aspect-ratio: 3 / 2;
          width: 100%;
          object-fit: cover;
        }
        .subscribe {
          position: absolute;
          top: 12px;
          right: 12px;
          width: auto;
          font-size: 14px;
          white-space: nowrap;
          border-radius: 4px;
          background: $secondary;
          color: $white;
          padding: 0 10px;
        }
        .remove {
          position: absolute;
          @include centerFlex();
          font-size: 14px;
          top: 12px;
          left: 12px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: red;
          color: $white;
        }
      }
      .content {
        padding: 16px;
        h4 {
          font-size: 16px;
          font-weight: 600;
          color: $main;
          margin: 0;
        }
        .play_btn {
          min-width: 32px;
          max-width: 32px;
          height: 32px;
          i {
            font-size: 12px;
          }
          cursor: pointer;
          background: $secondary;
          color: $white;
          @include centerFlex();
          border-radius: 50%;
          &.lock {
            background: red;
          }
        }
        p {
          margin: 0;
          font-weight: 500;
          color: $main;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          gap: 4px;
          align-items: center;
          img {
            height: 16px;
          }
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
        .likes_container {
          display: flex;
          gap: 16px;
          span {
            color: $dark;
            line-height: normal;
          }
          .dislikes {
            display: flex;
            align-items: center;
            gap: 6px;
            button {
              border-radius: 50%;
              @include centerFlex();
              color: #cecece;
              font-size: 16px;
              &.active {
                color: red !important;
              }
            }
          }
          .likes {
            display: flex;
            align-items: center;
            gap: 6px;
            button {
              border-radius: 50%;
              @include centerFlex();
              color: #cecece;
              font-size: 16px;
              &.active {
                color: $secondary !important;
              }
            }
          }
          &.disabled {
            pointer-events: none;
          }
        }
      }
    }
    .job-card {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: $lightShadow;
      .img {
        width: 100%;
        img {
          aspect-ratio: 3 / 2;
          width: 100%;
          object-fit: cover;
        }
      }
      .content {
        padding: 16px;
        h4 {
          font-size: 18px;
          margin-bottom: 0;
          color: $secondary;
        }
        p {
          margin-bottom: 0;
          font-weight: 500;
          color: $main;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
        }
        a {
          width: 100%;
          @include centerFlex();
          background: $secondary;
          padding: 4px 0;
          border-radius: 8px;
          color: $white;
        }
      }
    }
    .video_wrapper {
      width: 100%;
      height: 100%;
      aspect-ratio: 3 / 2;
      border-radius: 16px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      isolation: isolate;
      .play-btn {
        cursor: pointer;
        width: 80px;
        height: 80px;
        background: $secondary;
        border-radius: 50%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        i {
          font-size: 28px;
          color: #fff;
        }
      }
      .waves {
        position: absolute;
        width: 180px;
        height: 180px;
        background: #ffffff6d;
        opacity: 0;
        filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        border-radius: 100%;
        right: -50px;
        bottom: -50px;
        z-index: -1;
        -webkit-animation: waves 3s ease-in-out infinite;
        animation: waves 3s ease-in-out infinite;
      }
      .wave-1 {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
      .wave-2 {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }
      .wave-3 {
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
      }
      @keyframes waves {
        0% {
          -webkit-transform: scale(0.2, 0.2);
          transform: scale(0.2, 0.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        }
        50% {
          opacity: 0.9;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
        }
        100% {
          -webkit-transform: scale(0.9, 0.9);
          transform: scale(0.9, 0.9);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        }
      }
    }
    .course-details {
      padding: 40px 0 0;
      .btn {
        width: 100%;
        @include centerFlex();
        background: $main;
        color: $white;
        padding: 10px 0;
        margin-top: 12px;
        transition: $transation;
        &:hover {
          background: $secondary;
        }
      }
      .alreadySub {
        margin-top: 12px;
        a {
          color: $secondary;
        }
      }
      .video_wrapper {
        height: unset;
        position: relative;
        span {
          position: relative;
          top: 8px;
          right: 8px;
          background: $secondary;
          color: $white;
          padding: 4px 8px;
          position: absolute;
          border-radius: 8px;
        }
      }
      .content {
        h4 {
          font-size: 24px;
          color: $main;
          font-weight: 600;
        }
        P {
          font-size: 14px;
          color: $gray;
          line-height: 26px;
        }
        h6 {
          font-size: 18px;
          color: $main;
          font-weight: 600;
        }
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin-bottom: 16px;
          @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
          }
          li {
            display: flex;
            align-items: flex-start;
            gap: 4px;
            font-size: 13px;
            color: $gray;
            line-height: 24px;
            &::before {
              content: "\f648";
              font-family: "Font Awesome 6 pro";
              color: $secondary;
            }
          }
          &.iconCheck {
            li::before {
              content: "\f00c";
            }
          }
        }
      }
    }
    .course_faqs {
      padding: 40px 0;
      background: $inputBG;
      .title {
        text-align: center;
        color: $main;
        font-size: 32px;
        margin-bottom: 32px;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          top: 45px;
          width: 80px;
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          background: $secondary;
        }
      }
    }
    .in_course_why_us {
      padding: 40px 0;
      background: $inputBG;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        .arrow {
          height: 100px;
        }
        h3 {
          text-align: center;
          color: $main;
          font-size: 32px;
          margin-bottom: 32px;
        }
      }
      .grantee_card {
        background: $white;
        box-shadow: rgba(189, 189, 189, 0.2) 0px 2px 8px 0px;
        height: 100%;
        padding: 16px;
        border-radius: 12px;
        @include centerFlex();
        flex-direction: column;
        .icon img {
          margin-bottom: 12px;
          height: 80px;
        }
        h4 {
          font-size: 22px;
          color: $secondary;
        }
        p {
          color: $gray;
          font-size: 14px;
          margin: 0;
          line-height: 24px;
          text-align: center;
        }
      }
    }
    .course_reviews {
      padding: 40px 0;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-bottom: 32px;
        h3 {
          text-align: center;
          color: $main;
          font-size: 32px;
          margin-bottom: 0;
        }
        p {
          font-size: 14px;
          color: $gray;
          text-align: center;
          margin: 0;
        }
      }
      .swiper-slide {
        height: auto;
      }
      .testimonial_card {
        border: 1px solid $second10;
        padding: 16px;
        gap: 8px;
        @include space-between();
        flex-direction: column;
        border-radius: 12px;
        gap: 12px;
        p {
          margin: 0;
          text-align: center;
          line-height: 24px;
        }
        .play_btn {
          min-width: 36px;
          max-width: 36px;
          height: 36px;
          font-size: 14px;
          cursor: pointer;
          background: $secondary;
          color: $white;
          @include centerFlex();
          border-radius: 50%;
        }
        .sound {
          @include centerFlex();
          gap: 12px;
          width: 100%;
          .waves_img {
            width: 100%;
            max-width: 100px;
            height: 48px;
            mask-image: url("../images/waves.svg");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            position: relative;
            .wave {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
              background-image: url("../images/waves.svg");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
            .overlay {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              z-index: 2;
              background-color: $secondary;
            }
          }
        }
        .img {
          width: 100%;
          background: $lightGray;
          @include centerFlex();
          img {
            width: 100%;
            object-fit: cover;
          }
          video {
            object-fit: contain;
            max-height: 240px;
          }
        }
        .description {
          height: 100%;
          @include centerFlex();
          p {
            font-size: 18px;
            font-weight: 600;
            font-style: italic;
            color: $main;
          }
        }
        .rate {
          @include centerFlex();
          flex-direction: column;
          gap: 4px;
        }
      }
    }
    .section_title {
      @include space-between();
      h3 {
        color: $secondary;
        font-size: 24px;
      }
      a {
        color: $main;
        transition: $transation;
        &:hover {
          color: $secondary;
        }
        i {
          margin-right: 4px;
          transform: translateY(2px);
        }
      }
    }
    .library_section {
      padding: 40px 0;
      @media screen and (max-width: 576px) {
        padding: 20px 0;
      }
      .create_list {
        button {
          border-radius: 8px;
          padding: 4px 12px;
          background: $secondary;
          color: $white;
        }
      }
      .list-card {
        padding: 12px;
        display: flex;
        border-radius: 12px;
        box-shadow: $lightShadow;
        gap: 8px;
        .icon {
          width: 80px;
          background: $inputBG;
          @include centerFlex();
          height: 80px;
          border-radius: 8px;
          img {
            height: 36px;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
          padding: 4px 0;
          h6 {
            color: $dark;
            font-size: 16px;
            margin: 0;
            font-weight: 600;
          }
          p {
            display: flex;
            align-items: center;
            gap: 4px;
            margin: 0;
            line-height: normal;
            font-size: 14px;
            color: $gray;
            img {
              height: 16px;
            }
          }
        }
        .buttons {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-right: auto;
          color: $secondary;
          a {
            @include centerFlex();
            background: $inputBG;
            color: $secondary;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            font-size: 14px;
            &:nth-child(2) {
              color: red;
            }
          }
        }
      }
      .swiper_pagination_title {
        width: 100%;
        @include space-between();
        h5 {
          color: $main;
          font-size: 20px;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 12px;
          img {
            height: 22px;
          }
        }
        .swiper_pagination {
          display: flex;
          align-items: center;
          gap: 12px;
          button {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            transition: .25s ease-in-out;
            @include centerFlex();
            border: 1px solid $main;
            color: $main;
            &:hover {
              background: $secondary;
              border-color: $secondary;
              color: $white;
            }
          }
        }
      }
      .swiper-slide {
        height: auto;
      }
      .listCard {
        padding: 12px 16px;
        border-radius: 12px;
        color: $dark;
        @include space-between();
        align-items: flex-end;
        h5 {
          font-size: 16px;
          font-weight: 600;
        }
        .img {
          img {
            height: 50px;
          }
        }
      }
      .noDataFound {
        width: 100%;
        @include centerFlex();
        gap: 8px;
        flex-direction: column;
        img {
          height: 280px;
        }
        h5 {
          font-size: 20px;
          font-weight: 600;
          color: $dark;
          margin: 0;
        }
        p {
          color: $gray;
          margin-bottom: 4px;
        }
        button,
        a {
          background: $secondary;
          color: $white;
          padding: 4px 24px;
          border-radius: 20px;
        }
      }
      .sound_img {
        width: 100%;
        aspect-ratio: 3 / 2;
        border-radius: 12px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .likes_container {
        display: flex;
        gap: 24px;
        span {
          color: $dark;
          line-height: normal;
        }
        .dislikes {
          display: flex;
          align-items: center;
          gap: 6px;
          button {
            border-radius: 50%;
            @include centerFlex();
            color: #cecece;
            font-size: 18px;
            &.active {
              color: red !important;
            }
          }
        }
        .likes {
          display: flex;
          align-items: center;
          gap: 6px;
          button {
            border-radius: 50%;
            @include centerFlex();
            color: #cecece;
            font-size: 18px;
            &.active {
              color: $secondary !important;
            }
          }
        }
        &.disabled {
          pointer-events: none;
        }
      }
      .sound_content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        h5 {
          font-size: 26px;
          color: $secondary;
          @media screen and (max-width: 567px) {
            font-size: 22px;
          }
        }
        p {
          line-height: 28px;
          font-size: 16px;
          color: $gray;
        }
        .subscribe {
          color: $white;
          background: $secondary;
          padding: 4px 24px;
          border-radius: 20px;
        }
        .buttons {
          display: flex;
          gap: 28px;
          .add_to_library {
            display: flex;
            align-items: center;
            gap: 8px;
            color: $dark;
            font-weight: 600;
            font-size: 14px;
            @media screen and (max-width: 567px) {
              font-size: 12px;
            }
            span {
              font-size: 13px;
              width: 32px;
              @include centerFlex();
              height: 32px;
              border-radius: 50%;
              color: $white;
              background: $secondary;
            }
            &.danger span {
              background: red;
            }
          }
          .play_btnn {
            background: $main;
            padding: 2px 12px;
            border-radius: 20px;
            display: flex;
            color: $white;
            width: 120px;
            justify-content: center;
            cursor: pointer;
            align-items: center;
            gap: 8px;
            i {
              font-size: 14px;
            }
          }
        }
        .play_btnn {
          background: $main;
          padding: 2px 12px;
          border-radius: 20px;
          display: flex;
          color: $white;
          width: 120px;
          justify-content: center;
          cursor: pointer;
          align-items: center;
          gap: 8px;
          i {
            font-size: 14px;
          }
        }
      }
    }
    .swiper {
      padding: 4px 4px 60px;
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $secondary;
      }
      .swiper-slide {
        height: auto;
      }
    }
    .feature_and_statistics {
      padding: 60px 0;
      .img {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
      .features {
        display: flex;
        gap: 60px;
        flex-direction: column;
        &.toTop {
          transform: translateY(-20px);
        }
        &.toBottom {
          transform: translateY(20px);
        }
        @media (max-width: 768px) {
          gap: 24px;
        }
        .feature {
          cursor: pointer;
          padding: 16px;
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 8px;
          overflow: hidden;
          background: $white;
          box-shadow: $lightShadow;
          transition: $transation;
          border-radius: 12px;
          p {
            font-size: 14px;
            line-height: 24px;
            color: $main;
            margin: 0;
          }
          h4 {
            font-size: 18px;
            font-weight: 600;
            color: $main;
            margin: 0;
          }
          &.active {
            background: $secondary;
            h4,
            p {
              color: $white;
              display: block;
            }
          }
        }
      }
      .statistic {
        padding: 24px;
        background: $inputBG;
        border-radius: 12px;
        @include centerFlex();
        flex-direction: column;
        p {
          font-size: 18px;
          margin: 0;
          color: $main;
          @media (max-width: 576px) {
            font-size: 14px;
          }
        }
        h3 {
          font-size: 36px;
          font-weight: 600;
          margin: 0;
          color: $secondary;
        }
      }
      .good_Experience {
        @include centerFlex();
        gap: 16px;
        flex-direction: column;
        .notSatisfied {
          padding: 12px 24px;
          border-radius: 49% 51% 51% 49% / 48% 51% 49% 52%;
          border: 2px solid $secondary;
          h5 {
            margin: 0;
            color: $secondary;
            font-size: 16px;
          }
        }
        .solutions {
          display: flex;
          gap: 32px;
          margin-top: 24px;
          width: 100%;
          @media (max-width: 576px) {
            flex-direction: column;
            gap: 16px;
          }
          .solution {
            padding: 16px 12px;
            border-radius: 12px;
            width: 100%;
            display: flex;
            gap: 12px;
            background: $main;
            flex: 1;
            .icon {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              background: $white;
              @include centerFlex();
              img {
                width: 50px;
              }
            }
            .text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              p {
                margin: 0;
                color: $white;
              }
              h6 {
                font-size: 18px;
                margin-bottom: 4px;
                color: $white;
              }
            }
            &:nth-child(2) {
              background: $secondary;
            }
          }
        }
      }
    }
    .library {
      padding: 60px 0 0;
      .title {
        width: 100%;
        text-align: center;
        color: $secondary;
        margin-bottom: 0;
      }
      .section_subtitle {
        width: 100%;
        text-align: center;
        color: $main;
        margin-bottom: 32px;
        line-height: 24px;
      }
    }
    .libirary_subscribe {
      padding: 40px 0;
      .title_section {
        h1 {
          text-align: center;
          color: $main;
          font-size: 32px;
          @media screen and (max-width: 576px) {
            font-size: 26px;
          }
        }
        p {
          text-align: center;
          font-size: 18px;
          color: $gray;
          @media screen and (max-width: 576px) {
            font-size: 14px;
          }
        }
      }
      .plan {
        height: 100%;
        padding: 22px 16px;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        .convertion {
          color: red;
          line-height: normal;
          margin: 0;
          font-weight: 600;
          font-size: 14px;
        }
        h3 {
          font-size: 28px;
          color: $main;
        }
        ul {
          display: flex;
          gap: 8px;
          position: relative;
          flex-direction: column;
          margin-bottom: 12px;
          li {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            position: relative;
            font-size: 14px;
            color: $gray;
            line-height: 24px;
            &::before {
              content: "\f058";
              font-weight: 600;
              font-size: 16px;
              color: $secondary;
              font-family: "Font Awesome 6 pro";
            }
          }
        }
        .line {
          margin: 16px 0;
          width: 100%;
          height: 1px;
          background: $inputStroke;
        }
        .price_subscribe {
          @include space-between();
          margin-top: auto;
          button {
            background: $secondary;
            color: $white;
            font-size: 16px;
            width: auto;
            padding: 2px 24px;
            border-radius: 100px;
            @include centerFlex();
          }
          .price {
            display: flex;
            align-items: center;
            gap: 8px;
            h5 {
              font-size: 20px;
              font-weight: 600;
              display: flex;
              align-items: center;
              gap: 4px;
              color: $secondary;
              margin: 0;
              span {
                font-weight: 600;
                font-size: 14px;
                color: $dark;
                line-height: normal;
              }
            }
            p {
              font-size: 12px;
              font-weight: 600;
              margin: 0;
              line-height: normal;
              color: $gray;
              text-decoration: line-through;
              span {
                line-height: normal;
              }
            }
          }
        }
      }
    }
    .our_parteners {
      padding: 60px 0;
      .slider_wrapper {
        background: $secondary;
        border-radius: 16px;
        padding: 32px;
        @include centerFlex();
        flex-direction: column;
        h3 {
          color: $white;
          font-size: 32px;
          margin-bottom: 8px;
        }
        p {
          color: $white;
        }
        .slider {
          width: 100%;
          .swiper {
            padding: 0;
          }
          .img {
            width: 100%;
            height: 140px;
            @include centerFlex();
            img {
              width: 100%;
              object-fit: contain;
              filter: brightness(0) invert(1);
              transition: $transation;
            }
            &::after {
              content: "";
              display: flex;
              position: absolute;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 0%;
              background-color: $white;
              transition: $transation;
              z-index: -1;
            }
            &:hover {
              img {
                filter: none;
              }
              &::after {
                content: "";
                height: 100%;
              }
            }
          }
        }
      }
    }
    .jobs_page {
      padding: 60px 0;
    }
    .what_we_offer {
      padding-bottom: 60px;
      .table_container {
        overflow: scroll;
        padding-bottom: 8px;
        width: 100%;
        &::-webkit-scrollbar {
          height: 3px;
        }
      }
      table {
        white-space: nowrap;
        width: 100%;
        border: 1px solid $inputStroke;
        border-collapse: collapse;
        border-radius: 16px;
        position: relative;
        // &::after {
        //   width: 250px;
        //   height: 100%;
        //   border: 2px solid $secondary;
        //   position: absolute;
        //   top: 0;
        //   border-radius: 12px;
        //   right: 250px;
        //   content: "";
        // }
        thead {
          tr {
            th {
              img {
                height: 80px;
              }
            }
          }
        }
        td,
        th {
          padding: 8px 16px;
          border: 1px solid $inputStroke;
          position: relative;
          min-width: 250px;
          max-width: 250px;
          white-space: normal;
          .icon {
            @include centerFlex();
          }
          .check {
            color: $secondary;
          }
          .x {
            color: #f02020;
          }
          &.circled {
            padding: 8px 24px;
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 8px;
              right: 8px;
              height: 8px;
              background: $secondary;
              border-radius: 50%;
            }
          }
        }
        thead th:nth-child(2) {
          border-left: 2px solid $secondary;
          border-top: 2px solid $secondary;
        }
        thead th:nth-child(1) {
          border-left: 2px solid $secondary;
        }
        tbody tr td:nth-child(2),
        tbody tr td:nth-child(1) {
          border-left: 2px solid $secondary;
        }
        tbody tr:last-child td:nth-child(2) {
          border-bottom: 2px solid $secondary;
        }
      }
    }
    .testimonials {
      padding: 60px 0 30px;
      background: $second10;
      .testimonial_card {
        height: 100%;
        border: 1px solid $second10;
        padding: 16px;
        gap: 8px;
        @include centerFlex();
        flex-direction: column;
        border-radius: 12px;
        p {
          margin: 0;
          text-align: center;
          line-height: 24px;
        }
        .play_btn {
          min-width: 36px;
          max-width: 36px;
          height: 36px;
          font-size: 14px;
          cursor: pointer;
          background: $secondary;
          color: $white;
          @include centerFlex();
          border-radius: 50%;
        }
        .sound {
          @include centerFlex();
          gap: 12px;
          width: 100%;
          .waves_img {
            width: 100%;
            max-width: 100px;
            height: 48px;
            mask-image: url("../images/waves.svg");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            position: relative;
            .wave {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
              background-image: url("../images/waves.svg");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
            .overlay {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              z-index: 2;
              background-color: $secondary;
            }
          }
        }
      }
    }
    .job_details {
      padding: 40px 0;
      .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .job_title {
          display: flex;
          flex-direction: column;
          gap: 4px;
          h4 {
            font-size: 28px;
            margin-bottom: 0;
            color: $main;
            i {
              color: $secondary;
              font-size: 20px;
            }
            @media screen and (max-width: 576px) {
              font-size: 18px;
            }
          }
          a {
            background: $secondary;
            color: $white;
            padding: 4px 16px;
            border-radius: 8px;
            transition: $transation;
            &:hover {
              background: $main;
            }
          }
          li {
            color: $main;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 4px;
            position: relative;
            &::before {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: $secondary;
            }
          }
        }
        .about_job {
          p {
            color: $gray;
          }
          h6 {
            color: $main;
            font-size: 16px;
            font-weight: 600;
          }
          ul {
            list-style: disc;
            color: $gray;
            padding-right: 16px;
            margin-bottom: 16px;
            li {
              line-height: 28px;
            }
          }
        }
      }
    }
    .apply-for-job,
    .contact_section {
      padding: 40px 0;
      h3 {
        text-align: center;
        color: $secondary;
        font-size: 28px;
        margin-bottom: 24px;
      }
      .form-ui {
        box-shadow: $lightShadow;
        border-radius: 16px;
        padding: 32px 24px;
        .form_group {
          @media (max-width: 576px) {
            flex-direction: column;
          }
        }
        .buttons {
          @include space-between();
          margin-top: 12px;
          button {
            width: auto;
            height: 48px;
            padding: 0 24px;
            margin: 0;
            border-radius: 8px;
            &.next {
              background: $secondary;
              color: $white;
            }
            &.back {
              background: transparent;
              border: 1px solid $main;
              color: $main;
            }
          }
        }
        textarea {
          height: 100px;
        }
        .cv_area {
          width: 100%;
          padding: 16px;
          background: $inputBG;
          gap: 6px;
          border-radius: 12px;
          flex-direction: column;
          border: 1px solid $inputStroke;
          cursor: pointer;
          color: $gray;
          @include centerFlex();
          input {
            display: none;
          }
          span {
            color: $dark;
            line-height: normal;
          }
          .content {
            display: flex;
            align-items: center;
            gap: 4px;
            p {
              margin: 0;
              line-height: normal;
            }
          }
        }
      }
      .contact_info {
        h3 {
          color: $main;
          font-size: 20px;
        }
      }
    }
    .profile_sec {
      padding: 40px 0;
      .form-ui .form_group {
        @media screen and (max-width: 576px) {
          flex-direction: column !important;
        }
      }
    }
    .terms_section {
      padding: 40px 0;
      h4 {
        font-size: 20px;
        color: $secondary;
      }
      p {
        color: $gray;
        a {
          color: $secondary;
        }
      }
      ul {
        margin-bottom: 16px;
        list-style: decimal;
        padding-right: 16px;
        li {
          color: $main;
        }
      }
    }
    .my_courses {
      padding: 40px 0;
      .my_courses_title {
        display: flex;
        align-items: center;
        gap: 16px;
        img {
          height: 36px;
        }
        h2 {
          color: $main;
          font-weight: 600;
          font-size: 22px;
          margin: 0;
        }
      }
      .noCourses {
        height: 400px;
        flex-direction: column;
        @include centerFlex();
        gap: 24px;
        h3 {
          color: #e4e4e4;
          font-weight: 700;
          font-size: 60px;
          @media screen and (max-width: 576px) {
            font-size: 40px;
          }
        }
        a {
          background: $secondary;
          color: $white;
          padding: 8px 24px;
          border-radius: 100px;
        }
      }
    }
    .course_dashboard {
      padding: 16px 0;
      .course_dashboard_router {
        padding-top: 0;
        .header {
          @include space-between();
          @media screen and (max-width: 576px) {
            gap: 8px;
            flex-direction: column-reverse;
          }
          .title {
            height: 40px;
            margin: 0;
            flex-direction: row;
            gap: 24px;
            h5 {
              color: $main;
              font-weight: 600;
              font-size: 18px;
              margin: 0;
            }
            .date {
              font-size: 14px;
              color: $secondary;
            }
            @media screen and (max-width: 576px) {
              align-self: flex-start;
            }
          }
          .buttons {
            @media screen and (max-width: 768px) {
              width: 100%;
              .dropdown {
                width: 100%;
              }
            }
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            background: $secondary;
            color: $white;
            white-space: nowrap;
            height: 40px;
            padding: 0 16px;
            border-radius: 4px;
            border: 0;
            @media screen and (max-width: 576px) {
              padding: 0 16px;
              width: 100%;
            }
            i {
              font-size: 14px;
            }
            transition: $transation;
            &:hover {
              background: $main;
            }
          }
        }
        .noStudents {
          height: 320px;
          @include centerFlex();
          flex-direction: column;
          h5 {
            text-align: center;
            color: #e4e4e4;
            font-weight: 700;
            font-size: 40px;
            @media screen and (max-width: 576px) {
              font-size: 28px;
            }
          }
          p {
            text-align: center;
            line-height: 26px;
          }
        }
        .noAppointments {
          height: 320px;
          @include centerFlex();
          flex-direction: column;
          img {
            height: 250px;
          }
          p {
            font-size: 20px;
            color: $main;
          }
          button {
            background: $secondary;
            color: $white;
            padding: 4px 32px;
            border-radius: 100px;
          }
        }
        .forWhom {
          color: $main;
          font-size: 18px;
          display: flex;
          align-items: center;
          gap: 8px;
          font-weight: 600;
          img {
            height: 20px;
          }
        }
        .time_table {
          padding-bottom: 4px;
          margin-top: 10px;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            height: 2px;
          }
          table {
            white-space: nowrap;
            width: 100%;
            border-collapse: collapse;
            border: 1px solid $inputStroke;
          }
          th {
            font-size: 14px;
            background: $inputBG;
            color: $main;
          }
          th,
          td {
            padding: 8px;
            border: 1px solid $inputStroke;
            button {
              padding: 0 12px;
              img {
                height: 22px;
              }
            }
          }
        }
        .course_reports {
          iframe {
            height: 600px;
            width: 100%;
          }
        }
        .dashboard_heading {
          color: $main;
          font-size: 18px;
          font-weight: 600;
        }
        .dashboard_sub_heading {
          font-size: 16px;
          margin: 8px 0 0;
          color: $secondary;
        }
        .images_grid {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          .file_upload {
            border: 1px dashed $secondary;
            border-radius: 12px;
            width: 170px;
            height: 150px;
            label {
              height: 100%;
              input {
                display: none;
              }
              @include centerFlex();
              img {
                height: 60px;
              }
            }
          }
          .uploaded_file {
            border: 1px solid $inputStroke;
            border-radius: 12px;
            width: 170px;
            position: relative;
            height: 150px;
            overflow: hidden;
            @include centerFlex();
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            button {
              position: absolute;
              top: 8px;
              left: 8px;
              width: 26px;
              font-size: 16px;
              height: 26px;
              border-radius: 50%;
              background: $secondary;
              color: $white;
            }
            .doc_icon {
              @include centerFlex();
              flex-direction: column;
              gap: 4px;
              span {
                font-size: 10px;
                line-height: normal;
                color: $secondary;
              }
              img {
                width: auto;
                height: 50px;
              }
            }
          }
        }
        .submit_btn {
          padding: 0 32px;
          width: fit-content;
        }
        .dashboard_course_card {
          display: flex;
          box-shadow: $lightShadow;
          border-radius: 12px;
          overflow: hidden;
          gap: 16px;
          .img {
            flex: 1;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content {
            padding: 16px 0;
            flex: 1;
            width: 100%;
            h6 {
              color: $secondary;
              font-size: 18px;
            }
          }
        }
        .appointment_grid {
          display: grid;
          gap: 16px;
          grid-template-columns: repeat(2, 1fr);
          @media screen and (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
    .my-students {
      padding: 40px 0;
      .header {
        @include space-between();
        .title {
          height: 40px;
          margin: 0;
          flex-direction: row;
          gap: 24px;
          h5 {
            color: $main;
            font-weight: 600;
            font-size: 22px;
            margin: 0;
          }
        }
        button {
          @include centerFlex();
          gap: 4px;
          background: $secondary;
          color: $white;
          white-space: nowrap;
          height: 40px;
          padding: 0 16px;
          border-radius: 4px;
          border: 0;
          i {
            font-size: 14px;
          }
          transition: $transation;
          &:hover {
            background: $main;
          }
        }
      }
    }
    .goal_details {
      width: 100%;
      height: 100%;
      padding: 20px 0;
      .inner_wrap {
        margin: 0 auto;
        width: min(100%, 500px);
        .start {
          width: 100%;
          margin-bottom: 28px;
          @include centerFlex();
          .circle {
            width: auto;
            height: 60px;
            border-radius: 12px;
            padding: 0 16px;
            @include centerFlex();
            // visibility: hidden;
            h5 {
              margin: 0;
              color: $secondary;
              font-size: 22px;
            }
          }
        }
        .goal_steps {
          display: flex;
          flex-direction: column;
          max-width: 350px;
          margin: 0 auto;
          gap: 80px;
          padding: 0px 12px;
          margin-bottom: 80px;
          li {
            align-items: center;
            gap: 16px;
            cursor: pointer;
            display: flex;
            position: relative;
            &::after {
              width: 2px;
              z-index: -1;
              height: 200px;
              content: "";
              top: -6px;
              left: 158px;
              border: 2px dashed $main;
              position: absolute;
              transform: rotate(45deg);
            }
            &:nth-child(even) {
              padding-left: 80px;
              flex-direction: row-reverse;
              .content {
                flex-direction: row-reverse;
              }
              &::after {
                top: -16px;
                left: 160px;
                transform: rotate(-45deg);
              }
              @media screen and (max-width: 390px) {
                padding-left: 50px;
                &::after {
                  top: -16px;
                  left: 130px;
                }
              }
            }
            &:nth-child(odd) {
              padding-right: 80px;
              justify-content: start;
              @media screen and (max-width: 390px) {
                padding-right: 84px;
                &::after {
                  top: -18px;
                  left: 144px;
                }
              }
              @media screen and (max-width: 360px) {
                padding-right: 60px;
              }
            }
            &.level {
              .circle {
                width: 50px;
                height: 50px;
                padding: 16px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                @include centerFlex();
                background: #efcb96;
                img {
                  width: 100%;
                }
              }
              .content {
                display: flex;
                flex-direction: column;
              }
            }
            &.step {
              .circle {
                min-width: 50px;
                max-width: 50px;
                height: 50px;
                padding: 12px;
                border-radius: 50%;
                @include centerFlex();
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                background: $main;
                img {
                  width: 100%;
                }
              }
              .content {
                display: flex;
                gap: 8px;
                max-width: 200px;
                align-items: center;
                img {
                  height: 64px;
                }
                h6 {
                  font-size: 14px;
                  line-height: 24px;
                  margin: 0;
                  color: $dark !important;
                }
              }
            }
            &.completed .circle {
              background: $secondary;
            }
            span {
              color: $dark;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        .goal_end {
          position: relative;
          width: 100%;
          border-radius: 600px 600px 0 0;
          height: 250px;
          background: $secondary;
          @include centerFlex();
          @media screen and (max-width: 420px) {
            height: 190px;
          }
          h5 {
            margin: 0;
            transform: translateY(22px);
            font-size: 54px;
            color: $white;
          }
          &::after {
            content: "";
            position: absolute;
            right: -10px;
            bottom: 0;
            height: 180px;
            background-size: cover;
            width: 84px;
            background-image: url('../images/ballon.png');
          }
          &::before {
            content: "";
            position: absolute;
            left: -10px;
            bottom: 0;
            height: 180px;
            background-size: cover;
            width: 84px;
            background-image: url('../images/ballon.png');
          }
        }
      }
    }
    .sideMenu {
      padding: 16px;
      background: $white;
      border-radius: 12px;
      background: $inputBG;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: scroll;
      .user_img {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding: 12px 8px;
        gap: 12px;
        .img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          overflow: hidden;
          border: 2px solid $inputStroke;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        h6 {
          color: $main;
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 0;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          width: 100%;
          a {
            width: 100%;
            color: $dark;
            padding: 6px 12px;
            display: flex;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            transition: $transation;
            font-size: 14px;
            font-weight: 600;
            img {
              height: 18px;
            }
            i {
              color: $secondary;
              &.fa-user-xmark {
                color: red;
              }
            }
            &:hover,
            &.active {
              background: $white;
            }
          }
        }
      }
      @media screen and (max-width: 768px) {
        top: 100px;
        position: fixed;
        right: -50%;
        height: 100%;
        width: 50%;
        border-radius: 0;
        z-index: 999;
        padding: 16px 8px;
        background: $white;
        transition: $transation;
        border-left: 1px solid $inputStroke;
        &.open {
          right: 0;
        }
        ul li a {
          &:hover,
          &.active {
            background: $inputBG;
          }
        }
      }
    }
    .open_menu {
      position: fixed;
      bottom: 24px;
      left: 24px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      z-index: 999;
      box-shadow: $lightShadow;
      @include clearInput();
      @include centerFlex();
      display: none;
      background: $secondary;
      color: $white;
      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }
  .subscribe {
    padding: 40px 0;
    .image {
      width: 100%;
      aspect-ratio: 3 / 2;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: $lightShadow;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .benifits {
      margin-top: 16px;
      h6 {
        font-size: 18px;
        color: $main;
        font-weight: 600;
      }
      ul {
        li {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 4px;
          font-size: 16px;
          color: $gray;
          line-height: 30px;
          &::before {
            content: "\f648";
            font-family: "Font Awesome 6 pro";
            color: $secondary;
          }
        }
        &.iconCheck {
          li::before {
            content: "\f00c";
          }
        }
      }
    }
    .course_header {
      margin-top: 24px;
      h3 {
        color: $secondary;
      }
      p {
        color: $gray;
        margin-bottom: 12px;
      }
    }
    form {
      label {
        color: $main !important;
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .time-group {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        .duration_check {
          cursor: pointer;
          @media (max-width: 576px) {
            max-width: 100%;
          }
          .time {
            min-width: 94px;
            height: 60px;
            @include centerFlex();
            flex-direction: column;
            background: $inputBG;
            border-radius: 12px;
            border: 1px solid $inputStroke;
            transition: $transation;
            span {
              line-height: 20px;
            }
            @media (max-width: 576px) {
              max-width: 100%;
            }
          }
          input {
            display: none;
            &:checked + .time {
              background: $secondary;
              border-color: $secondary;
              color: $white;
            }
          }
        }
      }
      h6 {
        color: $main;
        font-size: 16px;
        margin: 8px 0;
        font-weight: 600;
      }
      ul {
        display: flex;
        flex-direction: column;
        width: min(100% - 24px, 400px);
        li {
          label {
            cursor: pointer;
          }
          @include space-between();
          .check {
            @include centerFlex();
            gap: 8px;
          }
        }
      }
      .save {
        margin-top: 0 !important;
        width: min(100% - 24px, 200px);
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
    .total {
      width: min(100% - 24px, 400px);
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .price {
        @include space-between();
        h3,
        span {
          color: $main;
          font-weight: 600;
          font-size: 20px;
        }
      }
      .instalment_message {
        color: rgb(255, 0, 0);
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 8px;
      }
      .installmentCheck {
        align-items: center;
        label {
          cursor: pointer;
          h6 {
            margin: 0;
          }
        }
        input {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }
    }
    .discount {
      color: $secondary;
      font-size: 16px;
    }
    .check-field {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        font-size: 14px;
        line-height: 20px;
      }
      a {
        line-height: 20px;
        color: $secondary;
      }
    }
  }
  footer {
    padding: 40px 8px 16px;
    border-top: 1px solid $inputStroke;
    .img {
      img {
        height: 120px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        padding-left: 16px;
        a {
          color: $main;
          font-weight: 500;
          transition: $transation;
          &:hover {
            color: $secondary;
          }
        }
      }
    }
    .about {
      padding-left: 62px;
      @media (max-width: 576px) {
        padding: 0;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        color: $main;
        line-height: 26px;
      }
      .social {
        display: flex;
        margin-top: 16px;
        gap: 12px;
        li {
          padding: 0 !important;
        }
        a {
          width: 40px;
          height: 40px;
          @include centerFlex();
          background: $secondary;
          border-radius: 50%;
          transition: $transation;
          i {
            color: $white;
          }
          img {
            height: 16px;
          }
          &:hover {
            background: $main;
            transform: translateY(-4px);
          }
        }
      }
    }
    h3 {
      color: $main;
      font-weight: 600;
      font-size: 24px;
      @media (max-width: 992px) {
        margin-top: 32px;
      }
    }
    .copy {
      @include centerFlex();
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid #eee;
      p {
        color: $main;
        line-height: 26px;
        a {
          color: $secondary;
        }
      }
    }
  }
  .form-ui {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .input-field {
      width: 100%;
      display: flex;
      flex-direction: column;
      .upload_wrapper {
        height: 256px;
        width: 100%;
        background: $inputBG;
        border-radius: 12px;
        label {
          cursor: pointer;
        }
        i {
          font-size: 32px;
          color: $secondary;
        }
        @include centerFlex();
        input {
          display: none;
        }
        .img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          @include centerFlex();
          p {
            padding: 12px;
          }
          button {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: $secondary;
            position: absolute;
            top: 12px;
            left: 12px;
            i {
              font-size: 16px;
              color: $white;
            }
          }
          img {
            width: 100%;
            height: 100%;
            padding: 12px;
            object-fit: contain;
            object-position: center;
          }
        }
      }
      .pass-group {
        display: flex;
        button {
          @include clearInput();
          width: 60px;
          height: 54px;
          border: 1px solid $inputStroke;
          background: $inputBG;
          border-right: 0;
          border-radius: 12px 0 0 12px !important;
          color: $inputHint;
        }
        input {
          border-left: 0 !important;
          border-radius: 0 12px 12px 0 !important;
        }
      }
      input,
      textarea,
      select {
        width: 100%;
        height: 54px;
        direction: rtl;
        outline: none;
        border: 1px solid $inputStroke;
        border-radius: 12px;
        background: $inputBG;
        padding: 12px 16px;
        font-size: 14px;
        color: $dark;
        box-shadow: none !important;
      }
      textarea {
        height: 152px;
      }
      label {
        color: $dark;
        font-weight: 500;
        font-size: 14px;
        gap: 4px;
        align-items: center;
        display: flex;
      }
    }
    .phone-group {
      .PhoneInput {
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
        input {
          direction: ltr;
        }
        .PhoneInputCountryIcon {
          position: relative;
        }
        .PhoneInputCountryIconImg {
          width: 20px;
          position: absolute;
          top: -32px;
          left: 14px;
        }
        select {
          appearance: none;
          text-align: left;
          padding: 12px 6px 12px 42px;
        }
      }
      .phone-code {
        height: 54px;
        border-radius: 4px;
      }
    }
    .form_group {
      width: 100%;
      display: flex;
      gap: 8px;
    }
    .image-change-wrapper {
      border-radius: 12px;
      border: 1px solid $inputStroke;
      @include centerFlex();
      flex-direction: column;
      padding: 16px 16px 4px;
      .img-wrap {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: 1px solid $inputStroke;
        overflow: hidden;
        @include centerFlex();
        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
          object-position: center;
        }
      }
      .upload {
        input {
          display: none;
        }
        .plus {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: $secondary;
          @include centerFlex();
          cursor: pointer;
          i {
            font-size: 14px;
            color: $white;
          }
        }
      }
    }
    .otp-container {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      direction: ltr !important;
      @media (max-width: 576px) {
        gap: 8px;
      }
      .otp-input {
        border-radius: 12px;
        flex: 1;
        width: inherit;
        height: 80px !important;
        text-align: center;
        border: 1px solid $inputStroke;
        background: $inputBG;
        font-size: 28px;
        font-weight: 600;
        color: $main !important;
        outline: none;
        &::-webkit-inner-spin-button {
          display: none;
        }
        @media (max-width: 576px) {
          height: 60px !important;
        }
      }
    }
    .save {
      width: 100%;
      height: 48px;
      border-radius: 12px;
      background: $secondary;
      color: $white;
      margin-top: 12px;
    }
  }
  .modal-header {
    button {
      margin: 0 auto 0 0 !important;
    }
    .back-btnNN {
      margin: 0 !important;
      width: 22px;
      @include centerFlex();
      height: 22px;
      border-radius: 50%;
      font-size: 14px;
      color: $white;
      background-color: $secondary;
    }
  }
  .modal-title {
    color: $dark;
    font-size: 16px;
    font-weight: 600;
  }
  .gender_select {
    display: flex;
    flex-direction: column;
    label {
      font-size: 14px;
    }
    .gender_card {
      display: flex;
      gap: 8px;
      background-color: $inputBG;
      color: $dark;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid $inputStroke;
      padding: 12px 16px;
      transition: 0.3s;
      cursor: pointer;
      font-weight: 400 !important;
      font-size: 14px;
      width: 100%;
      img {
        height: 30px;
        width: 30px;
      }
      &:hover {
        background-color: $second20;
      }
      &.active {
        border: 1px solid $secondary;
        background-color: $second20;
      }
    }
    & > div {
      display: flex;
      gap: 8px;
    }
    input {
      visibility: hidden;
    }
    label {
      font-weight: 500;
    }
  }
  // accordion globals
  .accordion-button {
    box-shadow: none !important;
    padding: 16px 16px;
  }
  .accordion {
    border: none !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: none !important;
  }
  .accordion-item {
    border: 1px solid $lightGray !important;
    border-radius: 12px !important;
    overflow: auto;
  }
  .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: $secondary;
  }
  .accordion-button {
    text-align: start;
    .form-switch {
      display: flex;
      align-items: center;
      gap: 10px;
      input {
        box-shadow: none !important;
      }
    }
    &::after {
      content: "\f107";
      font-family: "Font Awesome 6 pro";
      background-image: none;
      transform: none !important;
      margin-left: 0;
      margin-right: auto;
    }
    &:not(.collapsed)::after {
      content: "\f106";
      background: none;
    }
  }
  .accordion-body {
    padding: 0 12px 12px;
    p {
      color: $gray;
      font-size: 16px;
      a {
        color: $secondary;
      }
    }
  }
  // ltr mode override
  &.en {
    direction: ltr;
    overflow-x: hidden !important;
    .back i {
      transform: scaleX(-1) !important;
    }
    .sideMenu {
      @media screen and (max-width: 768px) {
        right: initial;
        left: -50%;
        border-right: 1px solid $inputStroke;
        border-left: 0;
        &.open {
          left: 0;
          right: initial;
        }
      }
    }
    .open_menu {
      right: 24px;
      left: initial;
    }
    header {
      .drop_menu {
        padding: 8px 36px 8px 20px;
      }
    }
    .form-ui {
      .input-field {
        input {
          direction: ltr;
        }
        .pass-group {
          button {
            border-radius: 0 12px 12px 0 !important;
            border-left: 0 !important;
            border-right: 1px solid $inputStroke !important;
          }
          input {
            border-radius: 12px 0 0 12px !important;
            border-right: 0 !important;
            border-left: 1px solid $inputStroke !important;
          }
        }
      }
    }
    footer {
      .about {
        padding-right: 62px;
        padding-left: 0;
      }
      ul li {
        padding-left: 0;
        padding-right: 16px;
      }
    }
    main {
      .acoustics .list-card .buttons {
        margin-left: auto;
        margin-right: 0;
      }
      .section_title a i {
        transform: translateY(2px) scale(-1);
        margin-left: 4px;
      }
      .course-card {
        direction: ltr !important;
        .content a i {
          transform: scale(-1);
        }
      }
      .courses .inner_search__form::before {
        right: unset;
        left: 16px;
      }
      .job_details .content .about_job ul {
        padding-right: 0;
        padding-left: 16px;
      }
      .terms_section ul {
        padding-right: 0;
        padding-left: 16px;
      }
      .custom-card .content a i {
        transform: scale(-1);
      }
      .custom-card {
        direction: ltr !important;
      }
      .what_we_offer table td.circled:before {
        right: initial;
        left: 8px;
      }
      .custom-card .label {
        left: initial;
        right: 8px;
      }
      .goal_details .inner_wrap .goal_steps li::after {
        transform: rotate(-45deg);
      }
      .goal_details .inner_wrap .goal_steps li:nth-child(even) {
        &::after {
          right: 160px;
          transform: rotate(45deg);
        }
        @media screen and (max-width: 390px) {
          padding-right: 50px;
          &::after {
            top: -10px;
            left: 152px;
            transform: rotate(40deg);
          }
        }
      }
      .goal_details .inner_wrap .goal_steps li:nth-child(odd) {
        padding-left: 80px;
        padding-right: 0;
        @media screen and (max-width: 390px) {
          padding-left: 84px;
          &::after {
            top: -10px;
            left: 152px;
            transform: rotate(-40deg);
          }
        }
        @media screen and (max-width: 360px) {
          padding-left: 60px;
        }
      }
      .goal_details .inner_wrap .goal_steps li:nth-child(even) {
        padding-right: 80px;
        padding-left: 0;
      }
      .acoustics .swiper_pagination_title .swiper_pagination {
        flex-direction: row-reverse;
      }
    }
    .goal_card .icon i {
      transform: scale(-1);
    }
    .accordion-button::after {
      margin-left: auto;
      margin-right: 0;
    }
    .modal-header button {
      margin: 0 0 0 auto !important;
    }
    .audio-card {
      direction: ltr !important;
    }
  }
}
.button-86 {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: $white;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  &::after,
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all 0.4s;
  }
  &::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    background: $secondary;
    border-radius: 10px;
  }
  &::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
  }
  &:hover {
    &::before {
      transform: translate(5%, 20%);
      width: 110%;
      height: 110%;
    }
    &::after {
      border-radius: 10px;
      transform: translate(0, 0);
      width: 100%;
      height: 100%;
    }
  }
  &:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  }
}
.modal-header {
  border-bottom: 0;
  .btn-close {
    box-shadow: none !important;
    background-image: url("../images/close.svg");
  }
}
.modal-body {
  padding: 0 12px 12px;
  video {
    width: 100%;
    height: 300px;
  }
}
.Toastify__toast-body > div:last-child {
  line-height: 24px;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    right: 0;
    left: initial;
  }
}
.stars {
  display: flex;
  gap: 4px;
  li {
    color: $inputHint;
    &.star {
      color: $gold;
    }
  }
  img {
    height: 14px !important;
  }
}
.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 48px;
    color: $secondary;
  }
}
.hidden_audio {
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
  position: fixed;
  top: -999999px;
  left: -999999px;
}
.checkbox {
  width: 16px;
  appearance: none;
  position: relative;
  cursor: pointer;
  &::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    content: "";
    font-family: "FontAwesome";
    width: 16px;
    height: 16px;
    border: 1px solid $gray;
    border-radius: 4px;
    font-size: 10px;
    @include centerFlex();
    color: $white;
  }
  &.error {
    &::after {
      border-color: red;
    }
  }
  &:checked::after {
    content: "\f00c";
    background: $secondary;
    color: #fff;
    border-color: $secondary;
  }
}
.form-switch {
  input {
    box-shadow: none !important;
    &:checked {
      background-color: $secondary !important;
      border-color: $secondary;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
.wizard_tabs {
  border-bottom: 1px solid $inputBG;
  @include space-between();
  gap: 8px;
  flex-wrap: wrap;
  .wizard_tab {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;
    min-width: 130px;
    &.active {
      .num {
        background: $secondary;
        color: $white;
      }
      .content {
        h6 {
          color: $secondary;
        }
      }
    }
    .num {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background: #dce9c4;
      color: $dark;
      transition: $transation;
      @include centerFlex();
      font-size: 16px;
    }
    .content {
      h6 {
        font-size: 16px;
        color: $gray;
        transition: $transation;
        line-height: 24px;
        margin: 0;
      }
      p {
        font-size: 12px;
        color: $gray;
        line-height: 24px;
        margin: 0;
      }
    }
  }
}
.subcribeModal {
  .loginOrRegiester {
    .title {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      h3 {
        font-size: 22px;
        color: $dark;
        margin: 0;
      }
      p {
        color: $gray;
        span {
          color: $secondary;
          cursor: pointer;
        }
      }
    }
  }
  .tabs {
    width: 100%;
    border-bottom: 2px solid $inputStroke;
    margin-bottom: 12px;
    button {
      @include clearInput();
      padding: 0 12px;
      position: relative;
      font-size: 16px;
      font-weight: 500;
      transition: $transation;
      color: $dark;
      @media (max-width: 576px) {
        font-size: 14px;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        width: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        background: $main;
        transition: $transation;
      }
      &.active {
        color: $main;
        &::after {
          width: 100%;
        }
      }
    }
  }
  .forgot a {
    color: $secondary;
    line-height: 24px;
  }
  .pay_method {
    width: 100%;
    height: 146px;
    @media screen and (max-width: 576px) {
      height: 178px;
    }
    .content {
      height: 100%;
      transition: $transation;
      cursor: pointer;
      background: $inputBG;
      padding: 16px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border: 1px solid $inputStroke;
      .icon_title {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
          height: 32px;
        }
      }
      h6 {
        font-size: 20px;
        color: $secondary;
        line-height: 24px;
        margin: 0;
      }
      p {
        font-size: 12px;
        color: $gray;
        line-height: 24px;
        margin: 0;
      }
    }
    input {
      display: none;
      &:checked + .content {
        background: $second10;
        border-color: $secondary;
      }
    }
  }
  .save_btn,
  .back_btn {
    height: 54px;
    border-radius: 12px;
    background: $secondary;
    color: $white;
  }
  .back_btn {
    background: $second20;
    color: $secondary;
  }
  .complete_process {
    .subscribtion_info {
      flex: 3;
      @include space-between();
      flex-direction: column;
      &.active {
        max-width: 420px;
        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
      h4 {
        color: $dark;
        font-size: 20px;
        font-weight: 600;
      }
      ul {
        li {
          width: 100%;
          @include space-between();
          gap: 8px;
          b {
            font-weight: 400;
            display: flex;
            gap: 8px;
            align-items: center;
            color: $secondary;
          }
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .details {
        margin: 10px 0;
        color: $dark;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
      }
      .total {
        width: 100%;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        .price {
          @include space-between();
          h3,
          span {
            color: $main;
            font-weight: 600;
            font-size: 20px;
          }
        }
        .instalment_message {
          color: rgb(255, 0, 0);
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 8px;
        }
        .installmentCheck {
          align-items: center;
          label {
            cursor: pointer;
            h6 {
              margin: 0;
            }
          }
          input {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
      }
      &.libriry_content {
        align-items: flex-start;
        h2 {
          margin-bottom: 16px;
          font-size: 26px;
          color: $secondary;
        }
        ul {
          display: flex;
          gap: 8px;
          position: relative;
          flex-direction: column;
          margin-bottom: 12px;
          li {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 8px;
            position: relative;
            font-size: 14px;
            color: $gray;
            line-height: 24px;
            &::before {
              content: "\f058";
              font-weight: 600;
              font-size: 16px;
              color: $secondary;
              font-family: "Font Awesome 6 pro";
            }
          }
        }
        .price {
          display: flex;
          align-items: center;
          gap: 8px;
          h5 {
            font-size: 20px;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 4px;
            color: $secondary;
            margin: 0;
            span {
              font-weight: 600;
              font-size: 14px;
              color: $dark;
              line-height: normal;
            }
          }
          p {
            font-size: 12px;
            font-weight: 600;
            margin: 0;
            line-height: normal;
            color: $gray;
            text-decoration: line-through;
            span {
              line-height: normal;
            }
          }
        }
      }
    }
    .upload_photo {
      h6 {
        cursor: pointer;
        &.active {
          color: $secondary;
        }
      }
      flex: 2;
      .img {
        width: 100%;
        height: 256px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 3 / 2;
          object-fit: contain;
          object-position: center;
        }
      }
      .upload_wrapper {
        height: 256px;
        width: 100%;
        background: $inputBG;
        border-radius: 12px;
        label {
          cursor: pointer;
        }
        i {
          font-size: 32px;
          color: $secondary;
        }
        @include centerFlex();
        input {
          display: none;
        }
        .img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          button {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: $secondary;
            position: absolute;
            top: 12px;
            left: 12px;
            i {
              font-size: 16px;
              color: $white;
            }
          }
          img {
            width: 100%;
            height: 100%;
            padding: 12px;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}
.complete_process_btn {
  width: 35%;
  @media screen and (max-width: 576px) {
    width: 40%;
  }
}
.payUserName {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  span {
    line-height: inherit;
  }
  @include space-between();
}
.add-student .form-ui {
  gap: 10px !important;
  textarea {
    height: 110px !important;
  }
}
.students_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.student_card {
  box-shadow: $lightShadow;
  padding: 16px;
  border-radius: 12px;
  position: relative;
  .img_name {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    .img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .content {
      h6 {
        color: $main;
        font-size: 16px;
        margin: 0;
        font-weight: 600;
      }
      p {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          color: $secondary;
          font-weight: 600;
        }
      }
    }
  }
  .contactNumber {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-weight: 600;
      font-size: 14px;
      color: $secondary;
    }
  }
  button {
    width: 100%;
    transition: $transation;
    color: $white;
    padding: 2px;
    font-size: 14px;
    border-radius: 4px;
  }
}
.tableLoader {
  @include centerFlex();
  flex-direction: column;
  .loader--style8 {
    width: 80px;
    height: 80px;
  }
  svg path,
  svg rect {
    fill: $secondary;
  }
}
.appointments-modal {
  .tabs {
    display: flex;
    border-bottom: 3px solid $inputBG;
    .tab {
      flex: 1;
      position: relative;
      margin-top: 12px;
      @include centerFlex();
      h6 {
        color: $dark;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 50%;
        height: 3px;
        width: 0;
        background: $secondary;
        transition: $transation;
        transform: translateX(-50%);
      }
      &.active {
        &::after {
          width: 100%;
        }
        h6 {
          color: $secondary;
        }
      }
    }
  }
  .appontementheck {
    width: 100%;
    cursor: pointer;
    .content {
      border: 1px solid $inputStroke;
      @include centerFlex();
      flex-direction: column;
      padding: 24px 16px;
      border-radius: 12px;
      background: $inputBG;
      gap: 16px;
      img {
        height: 48px;
      }
      h4 {
        font-size: 18px;
        color: $dark;
        margin: 0;
      }
    }
    input {
      display: none;
      &:checked + .content {
        border: 1px solid $secondary;
        background: $second10;
        h4 {
          color: $secondary;
        }
      }
    }
  }
  .continue,
  .back {
    padding: 4px 24px;
    background: $secondary;
    width: auto;
    height: auto;
    border-radius: 4px;
    color: $white;
    font-size: 14px;
    transition: $transation;
    &:hover {
      background: $main;
    }
  }
  .back {
    background: $second20;
    color: $secondary;
    &:hover {
      background: $second20;
    }
  }
  .timingRow {
    display: flex;
    gap: 8px;
  }
  .slider_instructors {
    display: flex;
    gap: 8px;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .instructor_availabilty {
    h6 {
      color: $dark;
      font-weight: 600;
      font-size: 14px;
    }
    .availabilty_row {
      display: flex;
      gap: 8px;
      align-items: flex-end;
      .day {
        @include centerFlex();
        width: 120px;
        height: 54px;
        border: 1px solid $inputStroke;
        border-radius: 12px;
        background: $inputBG;
        padding: 12px 16px;
        font-size: 16px;
        color: $dark;
      }
    }
  }
}
.instructor-card {
  min-width: 250px;
  max-width: 250px;
  input {
    display: none;
    &:checked + .content {
      border: 1px solid $secondary;
      background: $inputBG;
    }
  }
  .content {
    cursor: pointer;
    background: $white;
    border: 1px solid $inputStroke;
    padding: 16px;
    transition: $transation;
    border-radius: 12px;
    @include centerFlex();
    gap: 8px;
    flex-direction: column;
    .img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $inputStroke;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    h6 {
      color: $secondary;
      font-size: 20px;
    }
    p {
      font-size: 16px;
      text-align: center;
      line-height: 24px;
      color: $gray;
      margin: 0;
    }
    ul {
      width: 100%;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      li {
        padding: 0 4px;
        display: flex;
        align-items: flex-start;
        gap: 8px;
        color: $dark;
        font-size: 16px;
        &::before {
          content: "\f00c";
          font-family: "Font Awesome 6 pro";
          font-weight: 900;
          color: $secondary;
        }
      }
    }
  }
}
.editAppointmentModal {
  .editappointment {
    span {
      color: $secondary;
    }
  }
}
.dropdown-item {
  text-align: initial;
}
.report_card {
  width: 100%;
  padding: 16px;
  box-shadow: $lightShadow;
  border-radius: 12px;
  @include space-between();
  gap: 8px;
  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    h6 {
      color: $dark;
      margin: 0;
    }
    p {
      margin: 0;
      line-height: 24px;
      font-size: 14px;
      color: $secondary;
    }
  }
  .pdf {
    width: 80px;
    @include centerFlex();
    flex-direction: column;
    gap: 8px;
    padding: 12px 8px 8px;
    cursor: pointer;
    height: 90%;
    border: 1px solid $inputStroke;
    border-radius: 4px;
    img {
      height: 50px;
    }
    span {
      color: $secondary;
      font-size: 14px;
      line-height: 12px;
    }
  }
}
.row_header h6 {
  color: $main;
  font-weight: 600;
  font-size: 18px;
}
.assignment_card {
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 22px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: $lightShadow;
  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h6 {
      color: $secondary;
      margin: 0;
      font-size: 18px;
    }
    p {
      margin: 0;
      line-height: 24px;
      font-size: 14px;
      color: $gray;
      margin-bottom: auto;
    }
    .date {
      font-size: 14px;
      color: $secondary;
      margin: 0;
    }
    .img_name {
      display: flex;
      align-items: center;
      gap: 8px;
      .img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid $inputStroke;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      h6 {
        color: $dark;
        margin: 0;
        font-size: 16px;
      }
    }
  }
  .student {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    .img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid $inputStroke;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    h6 {
      font-size: 12px;
      color: $dark;
      white-space: nowrap;
      font-weight: 600;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
}
.name_img {
  display: flex;
  align-items: center;
  gap: 8px;
  .img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $inputStroke;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  h6 {
    color: $dark;
    margin: 0;
    font-size: 14px;
  }
}
.voice_field {
  display: flex;
  align-items: center;
  gap: 16px;
  .mic_btn {
    min-width: 48px;
    height: 48px;
    background: $inputBG;
    border-radius: 4px;
    img {
      height: 20px;
    }
  }
  .uploaded_voice {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    audio {
      width: 85%;
    }
    button {
      background: red;
      color: $white;
      border-radius: 50%;
      min-width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      i {
        font-size: 12px;
      }
    }
  }
}
.appointment_card {
  background: $inputBG;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  h5 {
    margin: 4px 0;
    color: $dark;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
  .cancel {
    background: red;
    width: 100%;
    color: $white;
    border-radius: 4px;
  }
  .edit {
    background: $secondary;
    width: 100%;
    color: $white;
    border-radius: 4px;
  }
  .countDownTimer {
    padding: 4px 0;
    @include centerFlex();
    gap: 16px;
    .block {
      @include centerFlex();
      flex-direction: column;
      gap: 2px;
      span {
        font-size: 12px;
        color: $dark;
        font-weight: 600;
      }
      .count {
        width: 54px;
        @include centerFlex();
        background: $secondary;
        color: $white;
        padding: 4px 0;
        font-weight: 400;
        font-size: 16px;
        border-radius: 4px;
      }
    }
  }
  button {
    @include centerFlex();
    width: 100%;
    height: 40px;
    background: $main;
    color: $white;
    border-radius: 4px;
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
.hide_sm {
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.hide_lg {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}
.exeptional {
  background: $inputBG;
  position: relative;
}
.exeptional_text {
  background: $inputBG;
  td {
    text-align: center;
  }
}
.exiptionalCancel {
  background: $main;
  color: $white;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 12px;
}
.goal_card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  background: $white;
  gap: 16px;
  box-shadow: $lightShadow;
  h6 {
    color: $secondary;
    font-size: 20px;
  }
  p {
    margin: 0;
    font-size: 14px;
    color: $main;
    line-height: 24px;
  }
  .icon {
    @include centerFlex();
    width: 40px;
    i {
      color: $secondary;
    }
  }
}
.choose_student {
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  .student_check {
    cursor: pointer;
    input {
      display: none;
      &:checked + .content {
        .img {
          border-color: $secondary;
        }
        p {
          color: $secondary;
        }
      }
    }
    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 4px;
      .img {
        padding: 2px;
        width: 64px;
        height: 64px;
        transition: $transation;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid $inputStroke;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      p {
        margin: 0;
        color: $main;
      }
    }
  }
}
.studnet_answer {
  color: $secondary;
  font-size: 22px;
  margin-bottom: 12px;
}
.answer_field {
  width: 100%;
  padding: 12px;
  background: $inputBG;
  color: $dark;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
  p {
    margin: 0;
  }
}
.voice_answer {
  width: 100%;
  padding: 8px 0;
  audio {
    width: 100%;
  }
  margin-bottom: 30px;
}
.my-subscriptions {
  padding: 40px 0;
  .librarySubLink {
    margin-bottom: 24px;
    width: 100%;
    padding: 16px;
    box-shadow: $lightShadow;
    background: $main;
    border-radius: 12px;
    @include centerFlex();
    a {
      color: $white;
      text-align: center;
    }
  }
  .section_title {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    .img img {
      height: 36px;
    }
    h2 {
      color: $main;
      font-size: 18px;
      margin: 0;
    }
  }
  .buttons {
    margin-top: 12px;
    gap: 8px;
    @include space-between();
    button {
      width: 100%;
      font-size: 14px;
      border-radius: 4px;
      background: $secondary;
      color: $white;
      &.cancel {
        background: red;
      }
      &.upgrade {
        background: $main;
      }
    }
  }
}
.card_btns {
  display: flex;
  position: absolute;
  left: 8px;
  bottom: 8px;
  gap: 8px;
  button {
    @include centerFlex();
    width: 36px;
    height: 36px;
    background: $inputBG;
    border-radius: 50%;
    i {
      color: $secondary;
    }
    &:nth-child(2) {
      i {
        color: red;
      }
    }
  }
}
.confirm-delete {
  p {
    color: $main;
    font-size: 16px;
  }
  .cancel-btn {
    border: 1px solid $dark;
    color: $dark;
    width: 100%;
    padding: 4px;
    border-radius: 8px;
  }
  .delete-btn {
    width: 100%;
    padding: 4px;
    background: red;
    color: $white;
    border-radius: 8px;
  }
}
.add-discount {
  width: min(100%, 200px);
  height: 54px;
  border-radius: 12px;
  background: $secondary;
  color: $white;
}
.save_bttn {
  width: 100%;
  padding: 4px;
  background: $main;
  color: $white;
  border-radius: 8px;
}
.complaints {
  padding: 16px 0;
  .title {
    gap: 12px;
    h2 {
      color: $main;
      font-size: 22px;
      font-weight: 600;
      margin: 0;
    }
  }
  button {
    background: $secondary;
    width: 100%;
    color: $white;
    padding: 8px;
    margin-top: 4px;
    border-radius: 12px;
  }
  .form_group {
    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
  }
}
.popup-modal {
  padding: 0 24px 16px;
  ul {
    li {
      display: flex;
      align-items: flex-start;
      gap: 4px;
      color: $gray;
      line-height: 28px;
      &::before {
        content: "\f648";
        font-family: "Font Awesome 6 pro";
        color: $secondary;
      }
    }
    &.iconCheck {
      li::before {
        content: "\f00c";
      }
    }
  }
  input[type="checkbox"] {
    width: 16px;
    appearance: none;
    position: relative;
    cursor: pointer;
    &::after {
      position: absolute;
      right: 50%;
      transform: translate(50%, -50%);
      top: 50%;
      content: "";
      font-family: "FontAwesome";
      width: 16px;
      height: 16px;
      border: 1px solid $inputHint;
      border-radius: 2px;
      font-size: 10px;
      @include centerFlex();
      color: $main;
    }
    &:checked::after {
      content: "\f00c";
      background: $main;
      color: #fff;
      border-color: $main;
    }
  }
}
.completed {
  color: $secondary !important;
}
.error {
  font-size: 14px;
  color: red;
}
.page_title {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: $inputBG;
  border-radius: 8px;
  gap: 12px;
  margin-bottom: 48px;
  h5 {
    color: $main;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  .buttons {
    button {
      background: $secondary;
      color: $white;
      border-radius: 4px;
      padding: 0 12px;
    }
  }
}
.back {
  @include centerFlex();
  width: 30px;
  height: 30px;
  background: $secondary;
  border-radius: 50%;
  i {
    font-size: 14px;
    color: $white;
  }
}
.rhap_container {
  direction: ltr !important;
}
.rhap_progress-indicator {
  width: 16px;
  height: 16px;
  top: -6px;
  box-shadow: none;
  background: $secondary;
}
.rhap_progress-filled {
  background: $secondary;
}
.rhap_time {
  color: $dark;
  font-size: 14px;
  font-weight: 500;
}
.rhap_button-clear {
  svg path {
    fill: $secondary;
  }
  svg {
    width: 24px;
  }
  &.rhap_play-pause-button svg {
    width: 36px;
  }
}
.rhap_container {
  box-shadow: none !important;
  border: none !important;
  padding-top: 4px !important;
}
.rhap_download-progress {
  background: $inputStroke;
}
.rhap_volume-bar {
  background: $inputStroke;
}
.rhap_volume-indicator {
  background: $secondary;
}
.close_player {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px;
  i {
    font-size: 18px;
    color: $secondary;
    cursor: pointer;
  }
}
.audio_player_container {
  position: sticky;
  bottom: 0;
  z-index: 9;
}
.audio_title {
  background: $white;
  color: $main;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 0 0;
  border-top: 1px solid $inputStroke;
  @include centerFlex();
  margin: 0;
}
.addAudioToPlayList {
  button {
    background: $main;
    color: $white;
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
    padding: 4px 24px;
    margin-top: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  .noPlayLists {
    @include centerFlex();
    flex-direction: column;
    h6 {
      font-size: 32px;
      color: $inputHint;
    }
  }
  .save {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    background: $secondary;
    color: $white;
  }
  .list_check_box {
    width: 100%;
    input {
      display: none;
      &:checked ~ .content {
        border: 2px solid $secondary;
        &::after {
          content: "\f058";
          color: $secondary;
        }
      }
    }
    .content {
      cursor: pointer;
      padding: 12px;
      display: flex;
      border-radius: 12px;
      border: 2px solid $inputStroke;
      transition: $transation;
      gap: 8px;
      position: relative;
      &::after {
        position: absolute;
        top: 0;
        left: 4px;
        content: "";
        font-family: "Font Awesome 6 pro";
      }
      .icon {
        width: 80px;
        background: $inputBG;
        @include centerFlex();
        height: 80px;
        border-radius: 8px;
        img {
          height: 36px;
        }
      }
      .contentt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 0;
        gap: 8px;
        padding: 4px 0;
        h6 {
          color: $dark;
          font-size: 16px;
          margin: 0;
          font-weight: 600;
        }
        p {
          display: flex;
          align-items: center;
          gap: 4px;
          margin: 0;
          line-height: normal;
          font-size: 14px;
          color: $gray;
          img {
            height: 16px;
          }
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-right: auto;
        color: $secondary;
        a {
          @include centerFlex();
          background: $inputBG;
          color: $secondary;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          font-size: 14px;
          &:nth-child(2) {
            color: red;
          }
        }
      }
    }
  }
}
.MuiInputBase-input {
  padding: 0 !important;
  height: auto !important;
  border: 0 !important;
  background: none !important;
}
.MuiFormControl-root {
  height: 54px;
  border: 1px solid $inputStroke;
  border-radius: 12px;
  display: flex;
  background: $inputBG;
  align-items: center;
  justify-content: center;
  fieldset {
    display: none !important;
  }
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none !important;
}
.MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100% !important;
}
.join_session {
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
  }
  a {
    top: 16px;
    right: 16px;
    background: $white;
    color: red;
    padding: 8px 24px;
    border-radius: 8px;
    position: absolute;
    font-size: 16px;
  }
}
.course_plans {
  padding: 60px 0;
  .title {
    text-align: center;
    color: $main;
    font-size: 32px;
    margin-bottom: 32px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 45px;
      width: 80px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      background: $secondary;
    }
  }
  .plan {
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .convertion {
      color: red;
      line-height: normal;
      margin: 0;
      font-weight: 600;
      font-size: 14px;
    }
    .planHeader {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      h3 {
        font-size: 28px;
        font-size: 600;
        margin: 0;
        text-align: center;
        color: $dark;
      }
      .price {
        display: flex;
        align-items: center;
        gap: 8px;
        h5 {
          font-size: 36px;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 4px;
          color: $secondary;
          margin: 0;
          span {
            font-weight: 600;
            font-size: 14px;
            color: $dark;
            line-height: normal;
          }
        }
        p {
          font-size: 12px;
          font-weight: 600;
          margin: 0;
          line-height: normal;
          color: $gray;
          text-decoration: line-through;
          span {
            line-height: normal;
          }
        }
      }
      .label {
        @include centerFlex();
        background: $inputBG;
        padding: 6px 16px;
        border-radius: 20px;
        width: fit-content;
        h6 {
          margin: 0;
          font-size: 14px;
          color: $dark;
        }
      }
    }
    ul {
      display: flex;
      gap: 8px;
      position: relative;
      flex-direction: column;
      margin-bottom: 12px;
      li {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        position: relative;
        font-size: 14px;
        color: $gray;
        line-height: 24px;
        &::before {
          content: "\f058";
          font-weight: 600;
          font-size: 16px;
          color: $secondary;
          font-family: "Font Awesome 6 pro";
        }
      }
    }
    .line {
      margin: 16px 0;
      width: 100%;
      height: 1px;
      background: $inputStroke;
    }
    .price_subscribe {
      display: flex;
      padding-top: 16px;
      button {
        background: $secondary;
        color: $white;
        width: 100%;
        font-size: 16px;
        padding: 4px;
        border-radius: 100px;
        @include centerFlex();
      }
    }
    &.active {
      border: 2px solid $secondary;
      box-shadow: none;
    }
  }
}
.roadmap {
  padding: 40px 0;
  .title {
    text-align: center;
    color: $main;
    font-size: 32px;
    margin-bottom: 32px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 45px;
      width: 80px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      background: $secondary;
    }
  }
  .pathGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    position: relative;
    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0 24px 0 36px;
    }
    &::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      left: 0;
      bottom: 0;
      border-bottom: 2px dashed $main;
      @media screen and (max-width: 576px) {
        height: 100%;
        width: 1px;
        left: 16px;
        border-bottom: 0;
        border-left: 2px dashed $main;
      }
    }
    .path {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;
      @media screen and (max-width: 576px) {
        flex-direction: row;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        width: 18px;
        height: 18px;
        background: $main;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        @media screen and (max-width: 576px) {
          left: -28px;
          top: 50%;
          bottom: initial;
          transform: translateY(-50%);
        }
      }
      .flipCard {
        width: 100%;
        height: 240px;
        @include centerFlex();
        position: relative;
        flex-shrink: 0;
        perspective: 1000px;
        user-select: none;
        @media screen and (max-width: 576px) {
          height: 180px;
          flex-shrink: initial;
        }
        .cardFront {
          width: 100%;
          height: 100%;
          padding: 24px;
          border-radius: 20px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          background-size: cover !important;
          background-position: top !important;
          background-repeat: no-repeat !important;
          backface-visibility: hidden;
          transform: rotateY(0deg);
          transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          .content {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: auto;
            gap: 4px;
            .job {
              color: $white;
              font-size: 12px;
            }
            .title {
              font-size: 18px;
              color: $white;
            }
          }
          .time {
            display: flex;
            gap: 24px;
            p {
              color: $white;
              font-size: 14px;
              i {
                margin-left: 4px;
              }
            }
          }
        }
        .cardBack {
          padding: 20px;
          position: absolute;
          border-radius: 20px;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          transform: rotateY(180deg);
          transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          box-shadow: $lightShadow;
          background-color: $white;
          .content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            .sponser {
              margin: 0;
              font-size: 16px;
              font-weight: 600;
            }
            img {
              height: 32px;
            }
            h2 {
              font-size: 18px;
              margin-top: 8px;
              margin-bottom: 0;
              color: $main;
              font-weight: 600;
              img {
                height: 24px;
                margin-left: 8px;
              }
            }
            p {
              font-size: 14px;
              line-height: 24px;
              color: $gray;
              margin: 0;
            }
            h6 {
              margin: 0;
              font-weight: 600;
              color: $main;
            }
            ol {
              padding: 0 16px 0 0;
              margin: 0;
              li {
                font-size: 14px;
                line-height: 24px;
                color: $gray;
                margin: 0;
              }
            }
            .btns {
              display: flex;
              gap: 32px;
            }
          }
        }
        &:hover {
          z-index: 2;
          .cardFront {
            transform: rotateY(-180deg);
          }
          .cardBack {
            transform: rotateY(0deg);
          }
        }
      }
      .path_title {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 16px;
        justify-content: center;
        white-space: nowrap;
        h3 {
          font-size: 20px;
          color: $dark;
        }
        h5 {
          font-size: 14px;
          color: $gray;
        }
        @media screen and (max-width: 576px) {
          margin: 0;
        }
      }
    }
  }
}
body.en .roadmap .pathGrid {
  @media screen and (max-width: 576px) {
    padding: 0 36px 0 24px;
    &::after {
      left: initial;
      right: 16px;
    }
    .path::after {
      right: -28px;
      left: initial;
    }
  }
}
